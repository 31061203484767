.alertperiodselection-blackfilm {
    background-color: rgba(0,0,0, 0.5);
    position: fixed; 
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; 
    margin: auto;
  }

  .alertperiodselection-popup {
    background-color : white;
    margin-top: 2rem;
  }

  .alertperiodselection-checkbox{
    font-family: sans-serif;  
    font-weight: 600;
    margin-left: 5rem;
    text-align: left;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }

  .alertperioddates {
    border: none;
    text-align: center;
    border: 1px solid black;
    margin-left: 2rem;
  }

  .alertdates {
      text-align: center;
  }

  .dateselectiontable {
      border: none;
  }

  .alerttable {
      margin: 1rem;
  }

  .daterange {
    margin-top: 1rem;
  } 

  label {
    text-align: left;
  }

  .inputrange {
    margin-left: 1rem;
  }

  .rangebox {
    margin-top: 2rem;
  }


