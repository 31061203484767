.DeviceStatusBox {
    box-sizing: border-box;
    /* margin-bottom: 1.5rem; */
    border-radius: 15px;
    padding: 0.8rem;
    margin: 1.5rem 2rem;
}

/* .sidebarInfo{
    margin: 2rem;
} */

.custom-modal-style {
    width: 400px;
    height: 50%;
}
.inputSearchBoxMap {
    border:1px solid var(--primaryColor);
    border-radius: 5px;
    height: 2.5rem;
}

.searchedToiletList{
    list-style: none;
    list-style-position: outside;
    align-self: start;
    /* box-shadow: 0rem 0.1rem 0.3rem var(--primaryColor); */
    border:1px solid var(--primaryColor);
    box-shadow: none;
    border-radius: 5px;
    width: 79%;
    margin-left: 0rem;
    margin-top: 0rem;
    max-height: 15rem;
    /* overflow-y: scroll; */
}

.searchFormLabel{
    margin-bottom: 0px;
    margin-top: 0.1rem;
    width: 100%;
    text-align: left;
    font-size: 0.9rem;
}

.qrcodeTextBtnSearchMap {
    display: inline-block; 
    cursor: pointer;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    outline: inherit;
}

.qrcodeTextBtnSearchMap:hover {
    cursor: pointer;
    color: var(--secondaryColor);
}

.qrcodeTextBtnSearchMap:focus {
    cursor: pointer;
    color: var(--secondaryColor);
}

.qrcodeTextBtnIconSearchMap {
    font-size: 1.3rem;
    margin-left: -1.7rem; 
    padding-right: 0.3rem;
}

.mapDeviceName{
    font-size: 1.5rem;
    font-weight: 600;
    color: gray;
    /* margin-top: 0.5rem; */

}

.mapEmojiIcon {
    font-size: 3.5rem;
}
.DeviceStatus{
    text-align: center;
    /* font-size: 1.2rem; */
    /* padding: 0.5rem; */

}

.AddrSubComp1{
    font-size: 1rem;
    text-transform: "capitalize";
    /* font-weight: 600; */
    color: gray;
    text-align: left;
    /* width: 30%; */
    /* flex: 2; */
}

.AddrSubComp2 {
    font-size: 1rem;
    text-transform: "capitalize";
    /* margin-left: 1rem; */
    text-align: left;
    /* flex: 5; */
}

.AddrMainDiv{
    display: flex;
    margin-bottom: 0.2rem;
    /* justify-content: center;
    align-items: center; */
}

.AddressInfo{
    /* margin-top: 1rem; */
    margin: 1.5rem 2rem;
    box-sizing: border-box;
    box-shadow: 0 0.3rem 0.7rem #464946;
    padding: 0.5rem;
    border-radius: 15px;
}
.sidebarBtn {
    margin: 0.5rem 0.5rem 0rem 0.7rem;
    display: flex;
    justify-content: flex-end;
}

.btnCloseSideBar{
    background-color: var(--primaryColor);
    border-color: var(--primaryColor);
    /* margin-left: 0.3rem; */
    color: white;
    font-size: 0.8rem;
    padding: 0px 6px;
    border-radius: 0.2rem;
}
.btnCloseSideBar:hover{
    background-color: var(--secondaryColor);
    border-color: var(--secondaryColor);
    font-size: 0.8rem;
    color: white;
    border-radius: 0.2rem;
}

.sidebar{
    background-color: white;
    max-width: 30%;
    right: 0 !important;
    left: auto !important;
}
  
.AddressComp {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
}

.DirectionIcon {
    font-size: 2.5rem;
    color: #007bff;
    cursor: pointer;
}

.DirectionIcon :hover {
    font-size: 2.5rem;
    color: var(--secondaryColor);
    cursor: pointer;

}

@media (max-width: 550px) {
    .sidebar{
        background-color: white;
        max-width: 80%;
        right: 0 !important;
        left: auto !important;
    }
    
    .searchedToiletList{
        width: 100%;
    }
}