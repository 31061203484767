.startAndEnddate {
    display: flex;
    margin-left: 0rem;
}

.divToShowInWeb{
    display: flex;
}

.divToShowInMob{
    display: none;
}

.iconButton{
    padding: "1rem";
    background-color: var(--primaryColor);
    color: "white";
}

.iconButton:hover {
    background-color: #FFA500;
    color: "white";
}

.ProdInfo {
    display: flex;
    margin-top: 1rem;
}

.ProdInfoDashboard {
    display: flex;
    margin-top: 2rem;
}

.ProdInfoDashboardBottom {
    display: flex;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.ProdInfolabel {
    width: 30%;
    font-size: 0.9rem;
}

.toiletRatingLabel {
    width: 20%;
    font-size: 0.9rem;
}

.ProdInfolabelDiscription {
    width: 30%;
    font-size: 0.9rem;
    margin-top: 0.2rem;
}

.ProdInfoInput {
    border: 1px solid var(--primaryColor);
    width: 60%;
    border-radius: 5px;
    height: 2rem;
}

.toiletFilterPincodeAndLevel {
    width: 60%;
    height: 2rem;
}

.subProdInfoFirst {
    flex: 1;
    margin-left: 5%;
}

.subProdInfoFirstDashboard {
    margin-left: 5%;
    display: flex;
    flex: 1.1;
}
 
.subProdInfoSecond {
    display: flex;
    flex:1
}

.selectedProdInfoFirst {
    flex: 0.9;
    margin-left: 3%;
}
 
.selectedProdInfoSecond {
    display: flex;
    flex: 1.1;
}

.dropDownOfModelInfo {
    display:flex;
    justify-content:center;
    align-items:center;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.contentsOfDashBoard {
    display:flex;
    justify-content:flex-start;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.selectedModelLable {
    font-size: 0.9rem;
}

.divForShowingDashBoard{
    display: flex;
    justify-content: space-between;
}

.divForEndDate{
    margin-left: 2rem;
}

.divForEndDateToiletRatingReport{
    margin-left: 0.9rem;
}

.divForSelectProdName{
    width: "50%";
}

.selectProdName {
    border: 1px solid var(--primaryColor);
    width: 90%;
    border-radius: 5px;
    height: 2rem;
}

.divToShowRefreshAndClearBtnDashBoard{
    margin-bottom: 0rem;
}

.dashBoardDateTimeZIndex{
    z-index: 99;
}

.dashBoardDateTimeZIndexToiletRatingReport{
    z-index: 9;
    width: 65%;
}

@media (max-width: 550px) {

    .divForEndDateToiletRatingReport{
        margin-left: -0.5rem;
    }

    .divToShowRefreshAndClearBtnDashBoard{
        margin-bottom: 0.5rem;
    }

    .divForShowingDashBoard{
        display: flex;
        flex-direction: column;
    }

    .divForEndDate{
        margin-left: -0.5rem;
    }

    .divForSelectProdName{
        width: "50%";
    }

    .selectProdName {
        border: 1px solid var(--primaryColor);
        width: 70%;
        border-radius: 5px;
        height: 2rem;
    }

    .startAndEnddate {
      display:flex;
      flex-direction: column;
    }

    .divToShowInWeb{
        display: none;
    }

    .divToShowInMob{
        display: flex;
        flex-direction: column;
    }

    .ProdInfo {
        display: flex;
        flex-direction: column;
    }

    .ProdInfoDashboard {
        display: flex;
        flex-direction: column;
        margin-top: 0.5rem;
    }

    .ProdInfoDashboardBottom {
        display: flex;
        flex-direction: column;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }

    .ProdInfolabel {
        width: 100%;
        font-size: 0.9rem;
    }

    .ProdInfolabelDiscription {
        width: 100%;
        font-size: 0.9rem;
        margin-top: 0.2rem;
        margin-bottom: 0.5rem;
    }
    
    .ProdInfoInput {
        border: 1px solid var(--primaryColor);
        width: 100%;
        border-radius: 5px;
        height: 2rem;
    }

    .subProdInfoFirst {
        flex: 1;
        margin-left: 0%;
    }

    .subProdInfoFirstDashboard {
        margin-left: 0%;
        margin-bottom: 0.5rem;
    }

    .subProdInfoSecond {
        display: block;
        margin-top: 0.5rem;
        flex:1
    }
    .DownloadAndViewButton {
        margin-top: 0.5rem;
    }
    .dropDownOfModelInfo {
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-items: flex-start;
    }
    .selectedModelLable {
        font-size: 0.9rem;
        margin-bottom: 0.5rem;
    }
}