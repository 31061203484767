  .adduser-blackfilm {
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 9999;
    backdrop-filter: blur(5px);
  }

  .deviceProfileBlackfilm {
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    backdrop-filter: blur(5px); 
    z-index: 1000; 
  }  

  .rc-slider-tooltip { 
    z-index: 1; 
  }

  .showPopUpForMaximumAxios {
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 99999;
    backdrop-filter: blur(3px);
  }
  
  .adduser-popup{
    margin-top: 2rem;
    background: white;
    border-radius: 1rem;
    padding-left: 10px;
    padding-right: 10px;
  }
  .deviceDetail{
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;
    margin-left: 0rem;
    text-align: center;
    font-weight: 700;
    padding-top: 1rem;
    /* color: var(--secondaryColor); */
    color: #498044;
  }
  
   .adduser-popup .update-form{
     margin-bottom: 1.2rem;
   }
   
   .adduser-form-label{
     margin-bottom: 0rem;
     margin-left: 1.5rem;
     text-align: left;
     font-size: 1rem;
     word-break: break-all;
     white-space: normal;
   }

   .addUserInputForm{
     margin-left: 1.5rem;
   }
   
   .adduser-popup .input-form
   {
    border: 1px solid var(--primaryColor);
    width: 90%;
    border-radius: 5px;
    height: 2.5rem;
    margin-left: 1.5rem;
   }


  
  .checkbox-text{
     margin: 0.3rem;
     font-size: 1rem;
  }  
  
  .adduserButton{
      border-radius: 5px;
      width: 30%;
      margin: 1rem;
      background-color: var(--primaryColor);
      /* background-color: #4b51b9; */
      color: white;
      padding: 0.4rem;
      font-size: 1.2rem;
  } 
  .addUserCheckbox{
    margin-left: 0rem;
  }
  
 
  .user-list{
    list-style: none;
    text-align: left;
  }
  .user-list-add{
    list-style: none;
    box-sizing: border-box;
    box-shadow: 0rem 0.1rem 0.3rem var(--primaryColor);
    width: 80%;
    margin-left: 2rem;
    margin-top: 1rem;
    max-height: 12rem;
    overflow-y: scroll;
  }

  .DeviceName {
    list-style: none;
    margin-top:0.5rem;
    text-align: left;
   
  }

  .checkbox-space{
    margin-right: 1rem;
  }
  .user-single{
   align-items: flex-start;
   padding: 0.5rem;
   margin: 0rem;
  }

  .inlineCSS{
    margin-left: 2rem;
    margin-top: 0.5rem;
  }

  input[type="checkbox"]
{
    position: relative;
    width: 1.4rem;
    height: 0.7rem;
    -webkit-appearance: none;
    background: #c6c6c6;
    outline: none;
    border-radius: 0.5rem;
    box-shadow: inset 0 0 5px rgba(0,0,0,.2);
    transition: .2s;
}
input:checked[type="checkbox"]
{
    background: var(--secondaryColor);
}
input[type="checkbox"]:before
{
    content: '';
    position: absolute;
    width:0.7rem;
    height: 0.7rem;
    border-radius: 0.5rem;
    top: 0;
    left: 0;
    background: #fff;
    transform: scale(1.1);
    box-shadow: 0 2px 5px rgba(0,0,0,.2);
    transition: .2s;
}
input:checked[type="checkbox"]:before
{
    left:0.8rem;
}

.input-form-datetime{
  width: 90%;

}

.react-datetime-picker__wrapper {
  margin-left:1.5rem;
  width: 100%;
}

.react-datetime-picker__inputGroup {
  display: -webkit-inline-box;
}

.checkbox-text{
  padding-left: 0rem;

}
.forevercheck{
  margin-left: 1.5rem;
  padding-top: 0.8rem;
} 