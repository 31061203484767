.complaint-form-label{
    margin-bottom: 0px;
    width: 200%;
    text-align: left;
    font-size: 0.9rem;
    width: 90% ;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    margin-left: -2.6rem; 
  }

  .reg-complaint-btn{
    border-radius: 5px;
    width: 30%; 
    margin-left: 35%;
    margin-right: 35%;
    margin-top: 0.5rem;
    background-color: var(--primaryColor);
    color: white;
  }

  @media(max-width: 550px){

    .complaint-form-label{
      margin-bottom: 0px;
      width: 200%;
      text-align: left;
      font-size: 0.9rem;
      width: 90% ;
      margin-bottom: 0.5rem;
      margin-top: 0.5rem;
      margin-left: -1rem;
    }
  
    .reg-complaint-btn{
      border-radius: 5px;
      width: 51%; 
      margin-left: 24.5%;
      margin-right: 24.5%;
      margin-top: 0.5rem;
      background-color: var(--primaryColor);
      color: white;
    }
  }

  @media(max-width: 1050px){

    .DisplayBoxForSelect{
      margin-top: 1.3rem;
    }
  }