
/* updateistration form & Heirarchy Definition Form has SAME CSS */

.RailwayPopup-blackfilm {
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
  
  .RailwayPopup-popup{
    margin-top: 0.5rem;
    padding: 1rem 1rem 0.2rem 1rem;
    box-sizing: border-box;
    margin-bottom: 1rem;
    background: white;
  
  }
  
   .RaiwayPopup-popup .railway-form{
     margin-bottom: 1.2rem;
   }
   
   .Railway-form-label{
     margin-bottom: 0rem;
     width: 100%;
     text-align: left;
     font-size: 0.9rem;
   }
   
   .RailwayPopup-popup .Railway-form
   {
    border: 1px solid var(--primaryColor);
    width: 100%;
    border-radius: 5px;
    height: 2.5rem;
   }
  
    .Railwaybtn {
      background-color: var(--primaryColor);
      border-color: var(--primaryColor);
      width: 80%;
      color: white; 
      margin: 1rem 0rem 1.5rem 0rem;
      padding: 0.5rem 1rem;
      border-radius: 0.3rem;
      font-size: 1.25rem;
      line-height: 1.5;
    }
    .RailwayPopup .deviceDetail{
      margin-top: 1rem;
      margin-bottom: 1rem;
      font-size: 1.5rem;
      margin-left: 0rem;
      text-align: center;
      font-weight: 700;
      padding-top: 1rem
    }
   
   
  
   