.first-section {
    background-image: url("../IMAGES/vtimg_plant.jpg");
    background-position: right;
    object-fit: cover;
}

.first-section-one {
    color: var(--secondaryColor);
    font-size: 2.4rem;
    font-weight: 600;
    margin: 10rem 0rem 10rem 0rem;
}

.second-section-one {
   background-color: white;
   font-size: 2rem;
   color: black;
}

.aboutHhmImg {
    margin-top: 5rem;
}

.second-section-two {
    background-color:white;
    margin: 5rem 0rem;
}

.second-section-two h2 {
    font-size: 2.5rem;
    font-weight: 600;
    color: var(--primaryColor);
}

.second-section-two h3 {
    font-size: 1.7rem;
    margin: 3rem 0rem 0rem 0rem;
    color: #6f6a6a;
}

.third-section {
    background-color: var(--primaryColor);
    width: 100%;
}

.third-section-one {
    margin: 5rem 0rem 0rem 0rem;
    font-size: 2.5rem;
    font-weight: 600;
    color: white;
}

.circle-text {
    display: table-cell;
    height: 5rem;
    width: 5rem;
    text-align: center;
    vertical-align: middle;
    border-radius: 50%;
    border: 2px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.circle-text-vrimri {
    display: table-cell;
    height: 7rem;
    width: 7rem;
    text-align: center;
    vertical-align: middle;
    border-radius: 70%;
    border: 5px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 1.5rem;
}

.third-section-row {
    color: white;
}

.parameters-first-row {
   margin-bottom: 2rem;
}

.parameters-first-row :hover ,.parameters-second-row :hover, 
.parameters-second-row-nonVriMri :hover, .parameters-third-row :hover {
    background-color: var(--secondaryColor);
}

.parameters-second-row {
    margin-bottom: 2rem;
}

.parameters-second-row-nonVriMri {
    margin-bottom: 2rem;
    align-items: center;
}

.parameters-third-row {
    margin-bottom: 2rem;
}

.mycontent [class$="inner"] {
    background-color: white;
    color: var(--secondaryColor);
}

@media(max-width: 550px){

    .parameters-first-row {
        margin-bottom: 2rem;
    }

    .parameters-second-row {
        margin-bottom: 2rem;
    }

    .parameters-third-row {
        margin-bottom: 2rem;
    }

    .parameters-second-row-nonVriMri {  
        margin-bottom: 2rem;
    }
    
    .circle-text-vrimri {
        display: table-cell;
        height: 7rem;
        width: 7rem;
        text-align: center;
        vertical-align: middle;
        border-radius: 70%;
        border: 5px solid white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-size: 1.5rem;
    }
} 
  




