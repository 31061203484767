/* //////////////////////////////           contact form Left side               //////////////////////////// */
.head-left{
    color: var(--primaryColor);
    margin-top: 1rem;
    padding:1rem; 
    font-weight: bold;
    text-align: left;

}
.sub-head{
    text-align: left;
    padding-left:2rem; 
    color: gray;

}
.sub-text{
    font-weight:bold; 
    margin-left:0.5rem;  
}
.sub-info{
    text-align: left;
    color: gray;
    font-weight:500; 
    padding-left:5rem; 

}
.contact-space1
{
    margin-bottom: 2rem;
    margin-top: 2rem;
}






/* //////////////////////////////           contact form Right side               //////////////////////////// */
.contact-box{
    box-sizing: border-box;
    box-shadow:  0 0.3rem 0.7rem var(--primaryColor);
    
}

.contact-form{
    margin: 2rem;
}

.head-right{
    color: var(--primaryColor);
    padding:1rem; 
    font-weight: bold;
}

.contact-form-label{
    margin-bottom: 0px;
    width: 100%;
    text-align: left;
    font-size: 1rem;
}

.input-contact{
   border:1px solid var(--primaryColor);
   width: 100%;
   margin-bottom:0.8rem; 
   border-radius: 5px;
   height: 2.5rem;
}

.input-Deviceprofile{
    border:1px solid var(--primaryColor);
    width: 95%;
    margin-bottom:0.5rem; 
    border-radius: 5px;
 }

.contact-space
{
    margin-bottom: 1rem;
}

 .contact-btn{
    background-color: var(--primaryColor);
    border-color: var(--primaryColor);
    width: 50%;
    text-transform: uppercase;
    color: white; 
    margin-bottom: 1rem;
    border-radius: 5px;
    padding:0.4rem;
    font-size: 1.2rem;
    
  }
  
   .contact-btn:hover
  {
    color: white;
  }