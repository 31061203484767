/* Define variables */
:root {
    /* --primaryColor: #a8cca8; */
    /* --primaryColor: #303597; */
    --secondaryColor: orange;
    --hoverLinkColor: rgb(86, 169, 202);
    --errorColor: red;
    --signalLevel1Color: red;
    --signalLevel2Color: rgb(255, 221, 0);
    --signalLevel3Color: green;
    --color-border: #d2d6dc;
    --color-shadow: rgba(0, 0, 0, 0.2);
    --border-radius: 0.25rem;
    --transition-speed: 0.15s;
}

/* Flexbox Utilities */
.flex {
    display: flex;
}

.absolute {
    position: absolute;
}

.relative {
    position: relative;
}

.flex-center {
display: flex;
justify-content: center;
align-items: center;
}

.justify-around {
justify-content: space-around;
}

.flex-row {
flex-direction: row;
}

.flex-col {
flex-direction: column;
}
  
.bg-pri {
    background-color: var(--primaryColor);
}

.bg-sec {
    background-color: var(--secondaryColor);
}

.text-pri {
    color: var(--primaryColor);
}

.text-sec {
    color: var(--secondaryColor);
}

.text-white {
    color: white
}

.text-black {
    color:black
}
  

.justify-between {
    justify-content: space-between;
}

.justify-center {
    justify-content: center;
}

.items-center {
    align-items: center;
}

.font-bold {
    font-weight: bold;
}

.italic {
    font-style: italic;
}

/* Background and Border Utilities */


.border {
    border: 1px solid var(--color-border);
}

.c-pointer {
    cursor: pointer;
}

.rounded {
    border-radius: var(--border-radius);
}

.rounded-full {
    border-radius: 50%
}


  


