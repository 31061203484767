.uListForParamDoc {
    padding-left: 20px; /* Adjusts the space before the bullet */
    margin-top: 10px; /* Adds spacing before the list */
    /* margin-bottom: 10px;  */
}

.listForParamDoc {
    /* Adds space between list items */
    list-style-type: disc; /* Ensures bullets are visible */
    font-family: 'Archivo', sans-serif;
    /* font-size: 1.25rem; */
    font-size: 1rem;
    /* line-height: 1.5; */
    text-align: justify;
    color: #1e1a1a;
    margin-bottom: 5px;
    /* margin-top: 10px; */
}

.card-head ul {
    list-style-position: initial; /* Aligns bullets with text */
    text-align: justify; /* Ensures all text is aligned to the left */
    color: #1e1a1a;
}

.boldText {
    font-weight:600;
    margin-top: 10px;
}

.headerThemeRight {
    position: absolute;
    height: 100%;
    width: 100%;
    right: 0;
    top: 0;
    overflow: hidden;
}

*, *::before, *::after {
    box-sizing: border-box;
}

.headerThemeLeft {
    position: absolute;
    height: 100%;
    width: 100%;
    right: 0;
    top: 0;
    overflow: hidden;
}

.headerThemeLeft:before {
    content: "";
    width: 300px;
    height: 150px;
    background: rgba(255, 255, 255, 0.23);
    position: absolute;
    left: -90px;
    top: 0;
    border-radius: 6px;
}

.headerThemeLeft:after {
    content: "";
    width: 150px;
    height: 600px;
    background: rgba(255, 255, 255, 0.364);
    position: absolute;
    left: 0px;
    top: 30px;
    border-radius: 4px;
}

.headerThemeRight:before {
    content: "";
    width: 300px;
    height: 150px;
    background: rgba(255, 255, 255, 0.23);
    position: absolute;
    right: -60px;
    top: 0;
    border-radius: 4px;
}

.headerThemeRight:after {
    content: "";
    width: 150px;
    height: 600px;
    background: rgba(255, 255, 255, 0.364);
    position: absolute;
    right: 0px;
    top: 30px;
}

.CardForParamFrontPage {
    background: white;
    border: 2px solid #c7e4c7 !important;
    cursor: pointer !important;
}

.CardForParamFrontPage:hover {
    border: 2px solid #c7e4c7 !important;
    background: #e0f0e0;
}

.informationSection {
    padding-bottom: 3rem;
    background-color: "white";
}

.containerForContent {
    padding-left: 150px;
    padding-right: 150px;
}

.rowForParamDoc {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; /* Align items properly */
}

/* .cardForParamDescription {
    background-color: transparent;
    border: none;
} */

.customizedCol12 {
    flex: 0 0 auto;
    width: 100%;
}

.card-box {
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid #003629;
}

.card-head {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    flex-grow: 1;
    padding: 10px;
}

.cardHeadForDetailsAndRanges {
    /* display: flex; */
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    flex-grow: 1;
    padding: 10px;
    text-align: left;
}


.card-title {
    color: #000000;
    opacity: 0.85;
    margin-bottom: 0;
}

.mainHeadings {
    font-family: 'Archivo', sans-serif;
    font-size: 1.75rem;
    line-height: 1.5;
}

.card-text {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 0;
    /* text-align: justify; */
}

.display-4 {
    font-family: 'Archivo', sans-serif;
    /* font-size: 1.25rem; */
    font-size: 1rem;
    line-height: 1.5;
    text-align: justify;
    color: #1e1a1a;
    margin-bottom: 5px;
    /* margin-top: 10px; */
}

.titleText  {
    font-size: 1.3rem;
    font-weight: bold;
}

/* .descriptionText {
    font-size: 1rem;
} */

.SubTextForInfoOfParam {
    text-align: justify;
    color: #1e1a1a;
    padding-bottom: 0;
}

.backbutton:hover{
    color: white;
    cursor: pointer;
}

.headingOf2ndPage {
    padding: 0.3rem;
    font-size: 2rem;
    font-weight: bold;
}

/* 
body{
   margin-top:20px;
   background:#f7f8fa
} */

.card {
    margin-bottom: 10px;
    -webkit-box-shadow: 0 2px 3px #eaedf2;
    box-shadow: 0 2px 3px #eaedf2; 
    /* border-right: none;
    border-left: none ; */

}

.pb-0 {
    padding-bottom: 0!important;
}

.font-size-16 {
    font-size: 16px!important;
}

/* .rounded-circle {
    border-radius: 50%!important;
} */

.paramForDetailsAndRanges{
    /* width: 1em; */
    /* height: 1em; */
    margin-top: .25em;
    vertical-align: top;
    /* background-color: #fff; */
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    /* border: 1px solid rgba(0, 0, 0, .25); */
    appearance: none;
    /* -webkit-print-color-adjust: exact; */
    /* print-color-adjust: exact; */
    float: left;
    margin-left: -1.5em;
}

.rangeTextforParamDoc {
    font-size: 1rem;
}

.rangeValueforParamDoc {
    font-size: 0.8rem;
    font-weight: 800;
}

.smileyForDetailsAndRanges{
    /* width: 1em; */
    /* height: 1em; */
    margin-top: .25em;
    vertical-align: top;
    /* background-color: #fff; */
    /* background-repeat: no-repeat;
    background-position: center;
    background-size: contain; */
    /* border: 1px solid rgba(0, 0, 0, .25); */
    appearance: none;
    /* -webkit-print-color-adjust: exact; */
    /* print-color-adjust: exact; */
    /* float: left;
    margin-left: -1.5em; */
}

.impactsOfDetailsAndRanges{
    margin-bottom: 0rem;
    margin-left: 1.5rem;
    text-align: left;
    font-size: 1rem;
    word-break: break-word;
    white-space: normal;
}


@media(max-width: 1300px){

    .containerForContent {
        padding-left: 20px;
        padding-right: 20px;
    }

    .headingOf2ndPage {
        padding: 1rem;
        font-size: 1.5rem;
        font-weight: bold;
    }

    .rangeTextforParamDoc {
        font-size: 0.9rem;
    }
    
    .rangeValueforParamDoc {
        font-size: 0.7rem;
        font-weight: 800;
    }

}

@media(max-width: 1050px){

    .containerForContent {
        padding-left: 20px;
        padding-right: 20px;
    }

    .headingOf2ndPage {
        padding: 1rem;
        font-size: 1.5rem;
        font-weight: bold;
    }

}

@media(max-width: 550px){

    .stickPageToTop {
        position: absolute;
    }

    .rangeTextforParamDoc {
        font-size: 0.8rem;
    }
    
    .rangeValueforParamDoc {
        font-size: 0.7rem;
        font-weight: 800;
    }

}