.removeuser-blackfilm {
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 9999;
    backdrop-filter: blur(5px);
  }
  
  .removeuser-popup{
    margin-top: 2rem;
    background: white;
    border-radius: 1rem;
    padding: 0px 20px 20px 10px;
  
  }
  .deviceName-removeUser{
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;
    margin-left: 0rem;
    text-align: center;
    font-weight: 700;
    padding-top: 1rem;
    /* color: var(--secondaryColor); */
    color: #498044;
  }
  .user-email-box{
    border: 1px solid var(--primaryColor);
    width: 90%;
    border-radius: 5px;
    height: 2.5rem;
    margin-left: 1.5rem;
  }
  .checkbox-distance {
    margin-right: 0.5rem;
  }
  
   .removeuser-form-label{
     margin-bottom: 0rem;
     margin-left: 1.5rem;
     text-align: left;
     font-size: 1rem;
   }
   .user-list-removeuser{
    list-style: none;
    box-sizing: border-box;
    box-shadow: 0rem 0.1rem 0.3rem var(--primaryColor);
    width: 80%;
    margin-left: 2.2rem;
    margin-top: 1rem;
    max-height: 12rem;
    overflow-y: scroll;
    margin-bottom: 0.7rem;
    border-radius: 0.4rem;
   }

   .AllDevice{
    list-style: none;
    margin-top:0.5rem;
    text-align: left;
   }

  .removeuserButton{
      border-radius: 5px;
      width: 35%;
      margin: 0.8rem;
      background-color: var(--primaryColor);
      color: white;
      border-radius: 5px;
      padding:0.4rem;
      font-size: 1.2rem;
  }

.input-form-datetime1{
  width: 95%;

}

.react-datetime-picker__wrapper {
  margin-left:1.5rem;
  width: 100%;
 }