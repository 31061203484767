.App {
    text-align: center;
    font-family: sans-serif;
  }
  .App-link {
    color: #61dafb;
  }
  
  .box{
    margin-top: 1rem;
    padding: 2rem;
    box-sizing: border-box;
    box-shadow:  0 0.3rem 0.7rem var(--primaryColor);
    margin-bottom: 1rem;
  }
  .box img{
   height: 5rem;
   box-sizing: border-box;
   /* box-shadow: 0 0.3rem 0.7rem #dcecdc; */
   width: 12rem;
  }
  .box .reg-form{
    margin-bottom: 1.2rem;
  }
  
  
  .reg-form-label{
    margin-bottom: 0px;
    width: 100%;
    text-align: left;
    font-size: 0.9rem;
  }

  .inputgroupCustom{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
  }
  
  .box .input-form
  {
   border:1px solid #abadb3;
   width: 100%;
   /* margin-bottom:0.8rem; */
   border-radius: 5px;
   height: 2.5rem;
   
  }
  
  .box .reg-btn{
    background-color: var(--primaryColor);
    border-color: var(--primaryColor);
    width: 100%;
    text-transform: uppercase;
    color: white; 
    border-radius: 5px;
    padding:0.4rem;
    font-size: 1.2rem;
  }

  .box .reg-btn:hover{
    background-color: var(--secondaryColor);
    border-color: var(--secondaryColor);
    width: 100%;
    text-transform: uppercase;
    color: white; 
  }
  
  
  .box .reg-btn:hover
  {
    color: white;
    
  }
  .box .foot a{
    /* color: #043e73; */
    margin: 1rem;
    padding-top: 1rem;
  }

  .termsandconditions a{
    /* color: #043e73; */
    font-weight: 900;
  }
  
  
  .box .foot
  {
    height: 2rem;
    /* border-top: 1px solid var(--primaryColor);  */
    /* margin-top: 1.3rem;  */
    /* margin-bottom: 0px; */
    text-align: center;
  }
  .box a:hover{
    text-decoration: underline;
  }
  .buttonErrorMessage {
    margin-top: 0.5rem;
  }

  .vcMapsSave{
    font-size: 1.2rem;
    border-radius: 6px;
    background-color: var(--primaryColor);
    border-color: var(--primaryColor);
    text-transform: uppercase;
    color: white; 
    padding: 0.3rem 1rem;
    margin: 0.5rem;
  }

  .vcMapsSave:hover{
    font-size: 1.2rem;
    border-radius: 6px;
    background-color: var(--secondaryColor);
    border-color: var(--secondaryColor);
    text-transform: uppercase;
    color: white; 
    padding: 0.3rem 1rem;
    margin: 0.5rem;
  }
  
  .vcMapsFooter{
    width: "100%";
    height: "15vh";
    overflow: "auto";
    margin-top: 0.8rem;
  }

  .vcMapsFooterContent{
    font-weight: bold;
    font-size: 1rem;
  }

  .loginMapBtn {
    text-decoration: none;
  }
  
  .loginMapBtn:hover {
    font-weight: bold;
  }

  
  @media(max-width: 550px){

    .vcMapsFooter{
      width: "100%";
      height: "15vh";
      overflow: "auto";
      margin-top: "1rem";
    }
  
    .vcMapsFooterContent{
      font-weight: 400;
      font-size: 0.8rem;
      padding: 0rem 0.5rem;
    }
    .vcMapsSave{
      font-size: 0.8rem;
      background-color: var(--primaryColor);
      border-color: var(--primaryColor);
      text-transform: uppercase;
      color: white; 
      margin-top: 0.3rem;
      padding: 0.5rem 1rem;
    }
  
    .vcMapsSave:hover{
      font-size: 0.8rem;
      position: 'absolute';
      background-color: var(--secondaryColor);
      border-color: var(--secondaryColor);
      text-transform: uppercase;
      color: white; 
      padding: 0.5rem 1rem;
    }
  }
  


  