.loggedInUserName {
  color: var(--secondaryColor);
  font-weight: 800;
  margin-left: 0.6rem;
  list-style: none;
  /* font-size: 1.4rem; */
  font-size: 1.2rem;
  text-transform: capitalize;
} 

.navbar {
  padding-left: 1rem;
  padding-right: 1rem;
}

.navitem {
  text-align: right;
  /* margin-top: 0.5rem; */
  /* margin-right: 1rem;
  font-size: 1.2rem; */
  margin-right: 0.6rem;
  font-size: 1rem;
  font-weight: 600;
  list-style: none;
}

.navitem_desktopViewLayout {
  text-align: right;
  margin-right: 0.6rem;
  font-size: 1rem;
  font-weight: 600;
  list-style: none;
}

.navitemSubMenu {
  text-align: right;
  font-size: 0.9rem;
  font-weight: 600;
  border-right: 2px solid white;
  /* padding-right: 0.5rem;
  padding-left: 0.5rem; */
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  padding-top: 0.1rem;
  padding-bottom: 0rem;
  list-style: none;
}

.nav-link {
  color: var(--primaryColor) !important;
  cursor: pointer;
}

.nav-link:hover {
  text-decoration: none !important;
}

.nav-link.active {
  color: var(--secondaryColor) !important;
  cursor: pointer;
}

.navitemSubMenu:last-child{
  border: none;
}

.navitem :hover {
  text-decoration: none;
  color:var(--hoverLinkColor);
}

.navitemSubMenu :hover {
  text-decoration: none;
  color:var(--hoverLinkColor);
}

.navitem-style{
  padding: 0.5rem;
}
.nav-pills  .navitem >a{
  color:var(--primaryColor);
}

.logOutButton{
  color: var(--secondaryColor);
}

.navBarSubMenuBand {
  background-color: var(--primaryColor);
}

.navBarSubMenuBandCRM {
  border-radius: 0.5rem;
  background-color: white;
  border-radius: 0.8rem;
  margin-bottom: 1rem;
  margin-left: 16px;
  border: 1px solid lightgray;
  margin-top: 10px;
  box-shadow: rgb(182 182 182 / 25%) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.logOutButton:hover{
  color:var(--hoverLinkColor);
  cursor: pointer;

}

/* ///////// */
.main-nav{
  text-align: right;
  text-decoration: none;
  padding-top: 0rem;
  padding-bottom: 0.2rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-Radius: 0.5rem;
  /* color: white; */
  color: #595959;
}

.navitem > .navbar-main-nav {
  text-align: right;
  text-decoration: none;
  padding-top: 0rem;
  padding-bottom: 0.2rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-Radius: 0.5rem;
  color: white;
}

.mainNavSubMenu {
  color: white;
  padding: 0.4rem;
  text-align: right;
  text-decoration: none;
}

.main-nav-active {
  /* color: white;
  background-color: var(--secondaryColor); */
  color: white;
  background-color: green;
  border-radius: 0.5rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  text-decoration: none;
}

.navbar-main-nav-active {
  background-color: var(--secondaryColor);
  color: white;
  background-color: green;
  border-radius: 0.5rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  text-decoration: none;
}

.main-nav-multilevel-active {
  color: white;
  background-color: var(--primaryColor);
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.mainNavActiveSubMenu {
  color: white;
  background-color: var(--secondaryColor); 
  border-radius: 0.5rem;
  padding: 0.4rem;
  text-decoration: none;
}

.mainNavSubMenu:hover {
  text-decoration: none;
  color:var(--hoverLinkColor);
}

.main-nav:hover{
text-decoration: none;
color:var(--hoverLinkColor);
}

.switchBtn{
  border: 2px solid var(--secondaryColor);
  width: 200px;
  height: 27px;
  text-align: center;
  border-radius: 20px;
  font-size: 1rem;
  font-style: bold;
  background-color: var(--secondaryColor);
  color: white;
}

/* Note: Pre-defined class changed. Might affect some other pre-defined class */
.navbar-light .navbar-toggler {
  color: rgba(0,0,0,.5);
  border-color: rgba(0,0,0,.1);
  margin: 0.2rem;
  align-content: right;
}

.navItemToBeDisplayed {
  display: flex;
  /* border: 1px black; */
}

.navBarDropDown{
  border: none; 
  border-Radius: 0.5rem;
  /* background-color: var(--primaryColor); */
  background-color: white;
  color: #595959;
  /* color: white; */
  text-decoration: none;
  text-align: right;
  outline: none;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.2rem;
  padding-bottom: 0rem;
}

.navBarDropDown:focus{
  outline: none;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.navBarDropDownActive{
  border-radius: 0.5rem;
  /* background-color: var(--secondaryColor); */
  background-color: green;
  color: white;
  /* color:white; */
  text-decoration: none;
  text-align: right;
  outline: none;
  border: 0px;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  padding-left: 0.7rem;
  padding-right: 0.7rem;
  margin-bottom: 0.1rem;
  margin-top: 0rem;

} 

.navBarDropDownActive:focus{
  outline: none;
}

.navBarDropDownsubmenuActive{
  border-style:ridge;
}

/* ---------- User profile css: Start --------------- */

.userProfileProfileIcon{
  padding-bottom: 0.3rem;
  font-weight: 600;
  /* --Commented-- */
  /* font-size: 1.5rem; */
  font-size: 1.3rem;
  color: var(--secondaryColor);
  cursor: pointer;
}

.userProfileProfileIcon:hover{
  text-decoration: none;
  color:var(--hoverLinkColor);
}

.userProfileFormLabel{
  text-align: left;
  margin-bottom: 0rem;
  margin-left: 0.3rem;
  font-size: 1rem;
  font-weight: 500;
}

.userProfileHeading{
  margin-bottom: 0.4rem;
  font-size: 1.5rem;
  margin-left: 0rem;
  text-align: center;
  font-weight: 700;
  /* color: var(--secondaryColor);  */
  color: #498044; 
}

.userProfileFormPopover {
  color: black;
  width: 32%;
  max-width: 32% !important;
  padding: "0rem";
  margin: "0rem";
}

.closeCurrentTab{
  color: var(--secondaryColor);
}

.closeCurrentTab:hover{
  color:var(--hoverLinkColor);
  cursor: pointer;
}

.showNavBarInMobView {
  display: block;
  padding: 0rem;
}

.showNavBarInWebView {
  display: none;
}

@media(max-width: 1050px){

    .userProfileFormPopover {
      color: black;
      width: 45%;
      max-width: 45% !important;
      padding: "0rem";
      margin: "0rem";
    }
    .navItemToBeDisplayed {
      display: flex;
      flex-direction: column;
    }
}  

@media(max-width: 550px){

  .userProfileFormPopover {
    color: black;
    width: 90%;
    max-width: 90% !important;
    padding: "0rem";
    margin: "0rem";
  }
  .navItemToBeDisplayed {
    display: flex;
    flex-direction: column;
  }
  .navitem {
    margin-top: 0.5rem;
    margin-right: 0.6rem;
    font-size: 1rem;
    font-weight: 400;
    list-style: none;
    padding-bottom:0.3rem;
  }

  .navitem_desktopViewLayout {
    display: none
  }

  .showNavBarInMobView {
    display: block;
  }

  .showNavBarInWebView {
    display: none;
  }

}

/* ---------- User profile css: End --------------- */
