.popup-main {
    height: 100vh;
    width: 100%;
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    top:0;
    left:0;
    right: 0;
    bottom: 0;
    margin: auto;
  } 
  
  .popup {
    background-color : white;
    margin-top: 2rem;
    /* position: absolute; */
  }
  
  .paragraph{
    padding-top: 2rem;
    font-size: 1rem;
    margin: 0rem 1rem 1.5rem 1rem;
    text-align: center;
    font-weight: 800;
    font-family: sans-serif;
  }
  
  .checkbox{
    font-family: sans-serif;  
    font-weight: 600;
    margin-left: 0.6rem;
  }
  
  .checkicon {
    margin-right: 0.3rem;
  }
  
  .row .appfBtn {
    background-color: var(--primaryColor);
    width: 80%;
    border-color: var(--primaryColor);
    margin:2rem 0rem 2rem 0rem;
  } 
  
  .row .appfBtn:hover {
    background-color: var(--primaryColor);
    border-color: var(--primaryColor);
  
  }
  .row .addbutton{
    height: 2rem;
    margin-bottom: 2rem;
  }
  
  .textfields .devicename {
    width: 100%;
    border-color: var(--primaryColor);
  } 
  
  .left {
    text-align: left;
  }
  
  fieldset.scheduler-border {
    border: 1px groove #ddd !important;
    padding: 0 1.4em 1.4em 1.4em !important;
    margin: 1rem 2rem 1rem 2rem !important;
    -webkit-box-shadow:  0px 0px 0px 0px #000;
            box-shadow:  0px 0px 0px 0px #000;
          
  }
  
  .scheduler-border {
    background-color: white;
  }
  
  legend.scheduler-border {
    font-size: 1em !important;
    font-weight: bold !important;
    text-align: left !important;
    width:inherit; 
    padding:0 0.2rem;
  }
  
  .arraysize{
    height: 13rem;
    overflow-y: scroll !important;
    box-shadow: 0rem 0.1rem 0.3rem var(--primaryColor);
    width: 90%;
    margin : 1rem;
  }
  
  .areaPincode {
    border: none;
    padding: 0.5rem 1rem;
  }
  
  .navbarcolor {
    background-color :black;
  }
  
  .navbrandcolor {
    color: var(--primaryColor);
  }
  
  
  