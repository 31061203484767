.faqbox{
    margin-top: 1rem;
    box-sizing: border-box;
    box-shadow:  0 0.1rem 0.7rem var(--primaryColor);
    margin-bottom: 1rem;
    border-radius: 10px;
    cursor: pointer;

  }
  .faq-que h5{
    font-family: sans-serif;
    font-weight: 900;
    color: var(--primaryColor);
    text-align: left;
    padding:0.5rem; 
  }
  
  .faq-que-content {
    padding-left: 0.5rem;
  }
  
  .faq-ans{
    font-family: sans-serif;
    font-weight: 500;
    color:#6f6a6a;
    font-size: 1.2rem;
    text-align: left;
    padding-left:1rem; 
    padding-bottom: 0.1rem; 
  }
  .faqbox-main{
    margin-top:2rem;
  }
  .main-h1{
    font-weight:900; 
  }
  
  
  
  