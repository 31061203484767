.tank {
    width: 115px;
    height: 130px;
    border: 2px solid black;
    background: white;
    position: relative;
    display: inline-block;
    border: 2px solid #000;
    border-radius: 5px;
    overflow: hidden;
}

.tank::before {
    content: '';
    position: absolute;
    width: 60px;
    height: 10px;
    border: 2px solid black;
    left: 32px;
    top: -10px;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
}

.water {
    position: absolute;
    background: #678AEE;
    width: 700%;
    height: 600%;
    transition: all 1s ease !important;
    -webkit-transition: all 1s ease !important;
    /* top: 20%; */
    bottom: 20%;
    left: -290%;
    border: 1px solid transparent;
    border-radius: 50% !important;
    animation-duration: 5s;
    animation-name: spin;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(270deg);
    }
}

.tank .one {
    position: absolute;
    width: 7%;
    bottom: 0;
    border-top: 2px solid black;
    text-decoration-color: red;
}

.tank .two {
    position: absolute;
    width: 8%;
    bottom: 0;
    border-top: 2px solid black;
}

.tank .three {
    position: absolute;
    width: 9%;
    bottom: 0;
    border-top: 2px solid black;
}

.tank .four {
    position: absolute;
    width: 10%;
    bottom: 0;
    border-top: 2px solid black;
}

.tank .five {
    position: absolute;
    width: 13%;
    bottom: 0;
    border-top: 2px solid black;
}

.averageWaterPerUser {
    text-align: left;
    margin-left: 1rem;
    font-size: 1rem;
    font-weight: 500;
}

.averageValWaterPerUser {
    font-size: 1rem;
    font-weight: 500;
}

.dvVcSingleDeviceData {
    display: flex;
    margin-top: 0.5rem;
}

.dateForFireAlarmAndGasLeaked {
    font-size: 1rem;
    color: white;
    box-shadow: 0.5px 0.5px 3px 1px #C00000;
    border-radius: 5px;
    background-color: rgb(215, 8, 8);
    display: flex;
    justify-content: center;
}

.jiggle {
    font-size: 1.5rem;
    color: white;
    animation: jiggle 0.3s linear infinite;

}
@keyframes jiggle {
    0%{
        rotate: 10deg;
    }
    50% {
        rotate: -10deg;
    }
    100% {
        rotate: 10deg;
    }
}

@keyframes blink {
    0% {
        opacity: 100%;
    }
    50% {
        opacity: 50%;
    }
    100% {
        opacity: 100%;
    }
    
}

.dateForDataReceived {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    width: 50%;
    margin-left: inherit;
    margin-right: inherit;
    align-items: center;
    box-shadow: 0.5px 0.5px 3px 1px #00b050;
    border-radius: 5px;
    background-color: #00b05014;
}

.dateForDataReceivedPFC {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    width: 50%;
    margin-left: inherit;
    margin-right: inherit;
    align-items: center;
    box-shadow: 0.5px 0.5px 3px 1px #00b050;
    border-radius: 5px;
    background-color: #00b05014;
}

.dateForDataReceivedWLI {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    width: 50%;
    margin-left: inherit;
    margin-right: inherit;
    align-items: center;
    box-shadow: 0.5px 0.5px 3px 1px #678AEE;
    border-radius: 5px;
    background-color: #e6eaf5;
}

.dateForDataReceivedSTLI {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    width: 50%;
    margin-left: inherit;
    margin-right: inherit;
    align-items: center;
    box-shadow: 0.5px 0.5px 3px 1px #bd9a38;
    border-radius: 5px;
    background-color: #eadfc0;
}

.dateForDataReceivedForToiletDashboard {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    width: 80%;
    margin-left: inherit;
    margin-right: inherit;
    align-items: center;
    box-shadow: 0.5px 0.5px 3px 1px #00b050;
    border-radius: 5px;
    background-color: #00b05014;
}

.rangeOfSensors {
    justify-content: "center";
}

.rangeIcon {
    /* color: var(--hoverLinkColor); */
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: bolder;
}

.rangeIcon:hover {
    color: orange;
    cursor: pointer;
    /* font-size: 1.2rem; */
    font-weight: bolder;
}

.tooltipForRangeIcon {
    position: relative;
    display: inline-block;
    color: var(--hoverLinkColor);
    cursor: pointer;
    z-index: 1;
    font-size: 1rem;
}

.tooltipForRangeIcon .tooltiptextForRangeIcon {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltipForRangeIcon .tooltiptextForRangeIcon::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.tooltipForRangeIcon:hover .tooltiptextForRangeIcon {
    visibility: visible;
    opacity: 1;
}


/* Tool tip to bottom left */
.tooltipForRangeIcon2 {
    position: relative;
    display: inline-block;
    color: var(--hoverLinkColor);
    cursor: pointer;
    z-index: 1;
    font-size: 1rem;
}

.tooltipForRangeIcon2 .tooltiptextForRangeIcon {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 125%;
    /* left: 50%; */
    margin-left: -120px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltipForRangeIcon2 .tooltiptextForRangeIcon::after {
    content: "";
    position: absolute;
    bottom: 100%;
    right: 0;
    /* margin-left: -5px; */
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.tooltipForRangeIcon2:hover .tooltiptextForRangeIcon {
    visibility: visible;
    opacity: 1;
}

/* Tool tip2 */
.boxForDetailsAndRanges {
    box-shadow: 1px 1px 5px 2px #C00000;
    border-radius: 5px;
    background-color: #c000001c;
    color: #C00000;
}

.mvVcSingleDeviceData {
    display: none;
}

.rangeSevereCategory {
    border-radius: 7px;
    padding: 0.6rem;
    background-color: #C00000;
}

.rangeVeryPoorCategory {
    border-radius: 7px;
    padding: 0.6rem;
    background-color: red;
}

.rangePoorCategory {
    border-radius: 7px;
    padding: 0.6rem;
    background-color: orange;
}

.rangeModerateCategory {
    border-radius: 7px;
    padding: 0.6rem;
    background-color: #FFD700;
}

.rangeSatisfactoryCategory {
    border-radius: 7px;
    padding: 0.6rem;
    background-color: #9acd32;
    /* background-color: #c0cd32; */
}

.rangeGoodCategory {
    border-radius: 7px;
    padding: 0.6rem;
    background-color: green;
}

.AllRangeCategory {
    border-radius: 7px;
    padding: 0.6rem;
    background-image: url("../IMAGES/NewFlag.png");
}

.rangeCategorydiv {
    border-radius: 5px;
    border: 1px solid lightgray;
    padding: 0rem 0.2rem;
    margin: 0.5rem 0rem 0.5rem 0.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sddSingleParam {
    /* margin-top: 1.5rem; */
    height: 88vh;
    /* width: 66vw; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.tooManyRequests {
    padding-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.airQualityRanges {
    min-width: 70px;
    margin: 4px;
    border-radius: 4px;
    font-size: .8em;
}

.airQualityBoxOuter {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 100%;
}

.airQualityBoxInner {
    border: 1px solid lightgray;

    border-radius: 20px;
    padding-top: 5px;
}


.airQualityBoxParent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-size: 15px;

}

.airQualityBoxChild {
    margin: 10px 0px;
    background-color: white !important;
}

.airQualityParamName {
    font-size: '1rem';
    text-align: start;
}

.airQualityRangeName {
    font-size: '.8rem';
}

.ParamsValueMissingOrWithoutRange {
    color: rgb(33, 31, 31);
    font-size: 2rem;

    
}

.ParamsValueRangeGood {
    color: #00b050;
    font-size: 2rem;
    
}

.windDirectionFont {
    color: #92CBDF;
    font-size: 2rem;
}

.ParamsValueRangeLowVPoor {
    color: red;
    font-size: 2rem;
}
.ParamsValueRangeLSevereLux {
    color: #606444;
    font-size: 2rem;
}

.ParamsValueRangeHSevereLux {
    color: #FF4500;
    font-size: 2rem;
}

.ParamsValueRangeGoodLux {
    color: #F89C3C;
    font-size: 2rem;
}
.ParamsValueRangeSatisfactory {
    /* color: #9acd32; */
    color: #9acd32;
    font-size: 2rem;
}

.ParamsValueRangePoor {
    color: orange;
    font-size: 2rem;
    
}

.ParamsValueRangeVeryPoor {
    color: red;
    font-size: 2rem;

    
}

.ParamsValueRangeSevere {
    color: #C00000;
    font-size: 2rem;

    
}

.ParamsValueRangeModerate {
    color: gold;
    font-size: 2rem;

    
}

.ParamsName {
    color: rgb(33, 31, 31);
    font-size: 1.8rem;
}

.PfcParamsNameForDashboard {
    color: rgb(33, 31, 31);
    font-size: 2rem;
}

.DeviceNameforToiletDash {
    color: rgb(33, 31, 31);
    font-size: 1.2rem;
}

.normalRange {
    font-size: 0.8rem;
    margin-top: -0.5rem;
}

.PfcCircularProgressBar {
    width: 5.7rem;
    margin-bottom: 0rem;
    margin-top: 0.5rem;
    /* height: auto; */
}

.NameOfParameter {
    /* color: grey; */
    font-weight: 500;
    text-transform: capitalize;
    font-size: 1rem;
}

.NameOfPfcParameter {
    /* color: grey; */
    
    text-transform: capitalize;
    font-size: 0.9rem;
    margin-top: 0.6rem;
    margin-bottom: 0;
}

.NameOfPFCParameterHeading {
    font-weight: 500;
    text-transform: capitalize;
    font-size: 1.3rem;
    margin-top: 0.6rem;
    margin-bottom: 0;
}

.unitOfPfcParameter {
    color: grey;
    font-size: 0.9rem;
    margin-top: 0;
    margin-bottom: 1rem;
}

.unitOfParameter {
    color: grey;
    font-size: 0.9rem;
    margin-top: 0;
    margin-bottom: 1rem;
}

.parameterOuterDivWithoutRange {
    box-shadow: 1px 1px 5px 2px grey !important;
    border-radius: 5px;
}

.parameterOuterDivForWLI {
    box-shadow: 1px 1px 5px 2px #678AEE;
    border-radius: 5px;
}

.parameterOuterDivForSTLI {
    box-shadow: 1px 1px 5px 2px #bd9a38;
    border-radius: 5px;
}

.parameterOuterDivForWLIAvg {
    background-color: #b0c0ea;
    border-radius: 5px;
    border-bottom-left-radius: 0%;
    border-bottom-right-radius: 0%;
    font-size: 1.3rem;
    font-weight: 500;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
}

.parameterOuterDivRangeGood {
    box-shadow: 1px 1px 5px 2px #00b050 !important;
    border-radius: 5px;
    background-color: #00b05014;
}



.parameterOuterDivRangeSatisfactory {
    /* box-shadow: 1px 1px 5px 2px #9acd32; */
    box-shadow: 1px 1px 5px 2px #9acd32 !important;
    border-radius: 5px;
    background-color: #9acd3214;
}

.dashboardBoxForState {
    border-radius: 1px;
    background-color: #b6c8de;
    height: 100px;
    width: 100%;
}

.dashboardBoxForStateZeroValue {
    border-radius: 1px;
    background-color: #b6c8de;
    height: 100px;
    width: 100%;
}

.dashboardBoxForStateZeroValue:hover {
    border-radius: 1px;
    background-color: #b6c8de;
    height: 100px;
    width: 100%;
}

.dashboardBoxForState:hover {
    border-radius: 1px;
    background-color: #6c93bb;
    height: 100px;
    width: 100%;
}

.parameterOuterDivRangePoor {
    box-shadow: 1px 1px 5px 2px orange !important;
    border-radius: 5px;
    background-color: #ffa5000d;
}

.parameterOuterDivRangeVeryPoor {
    box-shadow: 1px 1px 5px 2px red !important;
    border-radius: 5px;
    background-color: #ff00000f;
}

.parameterOuterDivRangeSevere {
    border-radius: 5px;
    background-color: #c000001c;
    box-shadow: 1px 1px 5px 2px #C00000 !important;
}

.parameterOuterDivRangeHSevereLux {
    box-shadow: 1px 1px 5px 2px #FF4500 !important;
    border-radius: 5px;
    background-color: #FF45001c;
}

.parameterOuterDivRangeLSevereLux {
    box-shadow: 1px 1px 5px 2px #606444 !important;
    border-radius: 5px;
    background-color: #6064441c;
}
.parameterOuterDivRangeGoodLux {
    box-shadow: 1px 1px 5px 2px #F89C3C !important;
    border-radius: 5px;
    background-color: #F89C3C1c;
}
/* .parameterOuterDivRangeModerate{
    box-shadow: 1px 1px 5px 2px #FFD700;
    border-radius: 5px; 
    background-color: #ffff000d;
}         */

.parameterOuterDivRangeModerate {
    /* border-right: 5px solid #FFD700;
    border-bottom: 5px solid #FFD700;
    border-left: 2.5px solid #FFD700;
    border-top: 2.5px solid #FFD700; */
    /* box-shadow: inset 0.5px 0.5px 1px 1px lightgray, 1px 1px 2px 2px lightgray; */
    box-shadow: 1px 1px 5px 2px #FFD700 !important;
    /* border-radius: 5px; */
    border-radius: 5px;
    background-color: #ffff000d;
}

.DeviceNameOfSD {
    font-size: 1.5rem;
    color: #666666;
    font-weight: 500;
    text-transform: capitalize;
    padding-bottom: 0.5rem;
}

.SupSubStyle {
    font-size: 1rem;
    color: rgb(33, 31, 31);
}

.labelForSingleDeviceDataPopup {
    margin-bottom: 0rem;
    margin-right: 0rem;
    text-align: left;
    font-size: 0.9rem
}

.iconSize {
    font-size: 2.5rem;
}

.paddingOfSDDBox {
    width: 100%;
    padding-right: 0rem;
    padding-left: 0rem;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 768px) {
    .airQualityBoxParent {
        gap: 30px;
    }
}

@media (min-width: 1200px) {
    .airQualityBoxInner {
        width: 80% !important;
    }


}

@media (min-width: 1370px) {
    .airQualityBoxInner {
        width: 75% !important;
    }
}

@media (max-width: 1370px) {

    .iconSize {
        font-size: 2rem;
    }

    .ParamsValueMissingOrWithoutRange {
        color: rgb(33, 31, 31);
        font-size: 1.5rem;
        
    }

    .ParamsValueRangeGood {
        color: #00b050;
        font-size: 1.5rem;
        
    }

    .windDirectionFont {
        color: #92CBDF;
        font-size: 1.5rem;
    }

    .ParamsValueRangeLowVPoor {
        color: red;
        font-size: 1.5rem;
    }

    .ParamsValueRangeLSevereLux {
        color: #606444;
        font-size: 1.5rem;
    }
    
    .ParamsValueRangeHSevereLux {
        color: #FF4500;
        font-size: 1.5rem;
    }
    
    .ParamsValueRangeGoodLux {
        color: #F89C3C;
        font-size: 1.5rem;
    }

    .ParamsValueRangeSatisfactory {
        color: #9acd32;
        font-size: 1.5rem;
    }

    .ParamsValueRangePoor {
        color: orange;
        font-size: 1.5rem;

        
    }

    .ParamsValueRangeVeryPoor {
        color: red;
        font-size: 1.5rem;
        
    }

    .ParamsValueRangeSevere {
        color: #C00000;
        font-size: 1.5rem;
        
    }

    .ParamsValueRangeModerate {
        color: gold;
        font-size: 1.5rem;
        
    }

    .ParamsName {
        color: rgb(33, 31, 31);
        font-size: 1.6rem;
    }

    .SupSubStyle {
        font-size: 0.9rem;
        color: rgb(33, 31, 31);
    }

    .NameOfParameter {
        font-weight: 500;
        text-transform: capitalize;
        font-size: 1.1rem;
    }

    .NameOfPfcParameter {
        
        text-transform: capitalize;
        font-size: 0.8rem;
        margin-top: 0.6rem;
        margin-bottom: 0;
    }

    .PfcCircularProgressBar {
        width: 4.6rem;
        margin-bottom: 0rem;
        margin-top: 0.5rem;
    }

    .unitOfParameter {
        color: grey;
        font-size: 0.9rem;
        margin-top: 0;
        margin-bottom: 1rem;
    }

    .unitOfPfcParameter {
        color: grey;
        font-size: 0.75rem;
        margin-top: 0;
        margin-bottom: 1rem;
    }

    .normalRange {
        font-size: 0.8rem;
        margin-top: -0.5rem;
    }

    .rangeIcon {
        color: var(--hoverLinkColor);
        cursor: pointer;
        font-size: 1.1rem;
        font-weight: bolder;
    }

    .averageWaterPerUser {
        text-align: left;
        margin-left: 1rem;
        font-size: 1rem;
        font-weight: 500;
    }

    .averageValWaterPerUser {
        font-size: 1rem;
        font-weight: 500;
    }
}

@media (max-width: 1270px) {
    .NameOfParameter {
        font-weight: 500;
        text-transform: capitalize;
        font-size: 0.9rem;
    }

    .NameOfPfcParameter {
        
        text-transform: capitalize;
        font-size: 0.8rem;
        margin-top: 0.6rem;
        margin-bottom: 0;
    }

    .PfcCircularProgressBar {
        width: 4.3rem;
        margin-bottom: 0rem;
        margin-top: 0.5rem;
    }

    .NameOfPFCParameterHeading {
        font-weight: 500;
        text-transform: capitalize;
        font-size: 1.2rem;
        margin-top: 0.6rem;
        margin-bottom: 0;
    }

    .parameterOuterDivForWLIAvg {
        background-color: #b0c0ea;
        border-radius: 5px;
        border-bottom-left-radius: 0%;
        border-bottom-right-radius: 0%;
        font-weight: 500;
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
        text-transform: capitalize;
        font-size: 1.2rem;
    }

    .unitOfPfcParameter {
        color: grey;
        font-size: 0.7rem;
        margin-top: 0;
        margin-bottom: 1rem;
    }
}

@media (max-width: 1210px) {
    .normalRange {
        font-size: 0.7rem;
        margin-top: -0.5rem;
    }
}

@media (max-width: 1188px) {
    .NameOfParameter {
        font-weight: 500;
        text-transform: capitalize;
        font-size: 0.9rem;
    }

    .NameOfPfcParameter {
        
        text-transform: capitalize;
        font-size: 0.73rem;
        margin-top: 0.6rem;
        margin-bottom: 0;
    }

    .NameOfPFCParameterHeading {
        font-weight: 500;
        text-transform: capitalize;
        font-size: 1rem;
        margin-top: 0.6rem;
        margin-bottom: 0;
    }

    .parameterOuterDivForWLIAvg {
        background-color: #b0c0ea;
        border-bottom-left-radius: 0%;
        border-bottom-right-radius: 0%;
        font-weight: 500;
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
        text-transform: capitalize;
        font-size: 1rem;
    }

}

@media (max-width: 1100px) {
    .NameOfParameter {
        font-weight: 500;
        text-transform: capitalize;
        font-size: 0.9rem;
    }

    .NameOfPfcParameter {
        
        text-transform: capitalize;
        font-size: 0.7rem;
        margin-top: 0.6rem;
        margin-bottom: 0;
    }

    .normalRange {
        font-size: 0.7rem;
        margin-top: -0.5rem;
    }
}

@media (max-width: 1094px) {
    .normalRange {
        font-size: 0.65rem;
        margin-top: -0.5rem;
    }
}

@media (max-width: 1080px) {
    .NameOfParameter {
        font-weight: 500;
        text-transform: capitalize;
        font-size: 0.8rem;
    }

    .NameOfPfcParameter {
        
        text-transform: capitalize;
        font-size: 0.67rem;
        margin-top: 0.6rem;
        margin-bottom: 0;
    }

    .normalRange {
        font-size: 0.65rem;
        margin-top: -0.5rem;
    }

    .NameOfPFCParameterHeading {
        font-weight: 500;
        text-transform: capitalize;
        font-size: 0.8rem;
        margin-top: 0.6rem;
        margin-bottom: 0;
    }

    .parameterOuterDivForWLIAvg {
        background-color: #b0c0ea;
        border-bottom-left-radius: 0%;
        border-bottom-right-radius: 0%;
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
        text-transform: capitalize;
        margin-bottom: 0;
        font-weight: 500;
        font-size: 0.8rem;
    }
}

@media (max-width: 1037px) {
    .normalRange {
        font-size: 0.6rem;
        margin-top: -0.5rem;
    }
}

/* Small screens, laptops. */
@media (max-width: 1024px) {
    .NameOfParameter {
        font-weight: 500;
        text-transform: capitalize;
        font-size: 0.8rem;
    }

    .NameOfPfcParameter {
        
        text-transform: capitalize;
        font-size: 0.64rem;
        margin-top: 0.6rem;
        margin-bottom: 0;
    }

    .iconSize {
        font-size: 1.5rem;
    }

    .normalRange {
        font-size: 0.6rem;
        margin-top: -0.5rem;
    }

    .rangeIcon {
        color: var(--hoverLinkColor);
        cursor: pointer;
        font-size: 0.8rem;
        font-weight: bolder;
    }
}

@media (max-width: 990px) {
    .NameOfParameter {
        font-weight: 500;
        text-transform: capitalize;
        font-size: 0.8rem;
    }

    .NameOfPfcParameter {
        
        text-transform: capitalize;
        font-size: 0.8rem;
        margin-top: 0.6rem;
        margin-bottom: 0;
    }

    .normalRange {
        font-size: 0.65rem;
        margin-top: -0.5rem;
    }
}

@media (max-width: 811px) {
    .NameOfParameter {
        font-weight: 500;
        text-transform: capitalize;
        font-size: 0.6rem;
    }

    .NameOfPfcParameter {
        
        text-transform: capitalize;
        font-size: 0.75rem;
        margin-top: 0.6rem;
        margin-bottom: 0;
    }

    .normalRange {
        font-size: 0.65rem;
        margin-top: -0.5rem;
    }

    .unitOfParameter {
        color: grey;
        font-size: 0.7rem;
        margin-top: 0;
        margin-bottom: 1rem;
    }

    .unitOfPfcParameter {
        color: grey;
        font-size: 0.6rem;
        margin-top: 0;
        margin-bottom: 1rem;
    }
}

@media (max-width: 780px) {

    .normalRange {
        font-size: 0.6rem;
        margin-top: -0.5rem;
    }
}

/* iPads, Tablets. */
@media (max-width: 768px) {

    .ParamsValueMissingOrWithoutRange {
        color: rgb(33, 31, 31);
        font-size: 1.2rem;
        
    }

    .ParamsValueRangeGood {
        color: #00b050;
        font-size: 1.2rem;
        
    }
    .windDirectionFont {
        color: #92CBDF;
        font-size: 1.2rem;
    }
    .ParamsValueRangeLowVPoor {
        color: red;
        font-size: 1.2rem;
    }
    .ParamsValueRangeLSevereLux {
        color: #606444;
        font-size: 1.2rem;
    }
    
    .ParamsValueRangeHSevereLux {
        color: #FF4500;
        font-size: 1.2rem;
    }
    
    .ParamsValueRangeGoodLux {
        color: #F89C3C;
        font-size: 1.2rem;
    }
    .ParamsValueRangeSatisfactory {
        color: #9acd32;
        font-size: 1.2rem;
    }

    .ParamsValueRangePoor {
        color: orange;
        font-size: 1.2rem;
        
    }

    .ParamsValueRangeVeryPoor {
        color: red;
        font-size: 1.2rem;
        
    }

    .ParamsValueRangeSevere {
        color: #C00000;
        font-size: 1.2rem;
        
    }

    .ParamsValueRangeModerate {
        color: gold;
        font-size: 1.2rem;
        
    }

    .ParamsName {
        color: rgb(33, 31, 31);
        font-size: 1.4rem;
    }

    .SupSubStyle {
        font-size: 0.8rem;
        color: rgb(33, 31, 31);
    }

    .unitOfParameter {
        color: grey;
        font-size: 0.7rem;
        margin-top: 0;
        margin-bottom: 1rem;
    }

    .normalRange {
        font-size: 0.7rem;
        margin-top: -0.5rem;
    }
}



/* Mobile devices. */
@media (max-width: 550px) {
    .airQualityRanges {
        width: 50px;
        padding: 4px;
        font-weight: 500;
        font-size: .7rem;
    }

    .aqiRangesParent {
        font-size: 12px;
    }

    .airQualityBoxParent {
        display: block;
        font-size: 15px;
        margin-top: 10px;

    }

    .airQualityBoxChild {
        margin-bottom: 15px;
    }

    .airQualityBoxInner {
        /* width: 100%; */
        width: 100% !important;
    }

    .airQualityParamName {
        font-size: '.8rem';
    }

    .airQualityRangeName {
        font-size: '.5rem';
    }

    .paddingOfSDDBox {
        width: 100%;
        padding-right: var(--bs-gutter-x, .75rem);
        padding-left: var(--bs-gutter-x, .75rem);
        margin-right: auto;
        margin-left: auto;
    }

    .sddSingleParam {
        margin-top: 1.5rem;
    }

    .parameterOuterDivWithoutRange {
        box-shadow: 1px 1px 5px 2px grey !important;
        border-radius: 5px;
    }

    .parameterOuterDivForWLI {
        box-shadow: 1px 1px 5px 2px #678AEE;
        border-radius: 5px;
    }

    .mvDateForFireAlarmAndGasLeaked {
        width: 100%;
        box-shadow: 0.5px 0.5px 3px 1px #C00000;
        border-radius: 5px;
        background-color: #c000001c;
    }

    .dateForDataReceived {
        display: none;
    }

    .dateForDataReceivedPFC {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        width: 100%;
        margin-left: inherit;
        margin-right: inherit;
        align-items: center;
        box-shadow: 0.5px 0.5px 3px 1px #00b050;
        border-radius: 5px;
        background-color: #00b05014;
    }

    .dateForDataReceivedWLI {
        display: none;
    }

    .dateForDataReceivedSTLI {
        display: none;
    }

    .mvDateForDataReceived {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        width: 100%;
        margin-bottom: 0.5rem;
        box-shadow: 0.5px 0.5px 3px 1px #00b050;
        border-radius: 5px;
        background-color: #00b05014;
    }

    .mvDateForDataReceivedWLI {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        width: 100%;
        margin-bottom: 0.5rem;
        box-shadow: 0.5px 0.5px 3px 1px #678AEE;
        border-radius: 5px;
        background-color: #e6eaf5;
    }

    .mvDateForDataReceivedSTLI {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        width: 100%;
        margin-bottom: 0.5rem;
        box-shadow: 0.5px 0.5px 3px 1px #bd9a38;
        border-radius: 5px;
        background-color: #eadfc0;
    }

    /* .dateForFireAlarmAndGasLeaked {
        display: none;
    } */

    .dvVcSingleDeviceData {
        display: none;
    }

    .dvDateTimeToBeDisplayed {
        display: none;
    }

    .mvVcSingleDeviceData {
        display: block;
        font-size: 2rem;
        color: #666666;
        font-weight: 500;
    }

    .rangeCategorydiv {
        border-radius: 5px;
        border: 1px solid lightgray;
        padding: 0.2rem 0.2rem;
        margin: 0.2rem 0rem 0.2rem 0.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .rangeSevereCategory {
        border-radius: 7px;
        padding: 0.6rem;
        background-color: #C00000;
    }

    .rangeVeryPoorCategory {
        border-radius: 7px;
        padding: 0.6rem;
        background-color: red;
    }

    .rangePoorCategory {
        border-radius: 7px;
        padding: 0.6rem;
        background-color: orange;
    }

    .rangeModerateCategory {
        border-radius: 7px;
        padding: 0.6rem;
        background-color: #FFD700;
    }

    .rangeGoodCategory {
        border-radius: 7px;
        padding: 0.6rem;
        background-color: green;
    }

    .rangeSatisfactoryCategory {
        border-radius: 7px;
        padding: 0.6rem;
        /* background-color: #9acd32; */
        background-color: #9acd32;
    }

    .AllRangeCategory {
        border-radius: 7px;
        padding: 0.6rem;
        background-image: url("../IMAGES/NewFlag.png");
    }

    .PfcCircularProgressBar {
        width: 4.9rem;
        margin-bottom: 0rem;
        margin-top: 0.5rem;
    }
}