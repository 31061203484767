
/* updateistration form & Heirarchy Definition Form has SAME CSS */

.InputFormVtAlertLog-blackfilm{
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.inputformvtalertlog{
  margin-top: 1rem;
  padding: 1rem 1rem 0.2rem 1rem;
  box-sizing: border-box;
  margin-bottom: 10rem;
  background: white;
  height: 40rem;
  overflow-y: scroll;
}

   .inputformvtalertlog .update-form{
     margin-bottom: 0.5rem;
   }
   
   .update-form-label{
     margin-bottom: 0rem;
     width: 100%;
     text-align: left;
     font-size: 0.9rem;
   }
   
   .inputformvtalertlog .input-form
   {
    border: 1px solid var(--primaryColor);
    width: 100%;
    border-radius: 5px;
    height: 2.5rem;
   }

    .updatebtn {
      background-color: var(--primaryColor);
      border-color: var(--primaryColor);
      width: 80%;
      color: white; 
      margin: 1rem 0rem 1rem 0rem;
      padding: 0.5rem 1rem;
      border-radius: 0.3rem;
      font-size: 1.25rem;
      line-height: 1.5;
    }
   
   

   