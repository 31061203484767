.parameterDropdown {
    width: 10.5rem;
}

.chartPeriodDropdwon {
    width: 6.4rem
}

.graphTypeDropdwon {
    width: 11rem;
}

/* 
Here Ant Design Stylings are overriden here which are thus now being set as the default stylings whenever & wherever
we use Ant Design Components associated to be below-like Ant Design Classnames: 

    EXAMPLE OF ANT DESIGN DEFAULT STYLINGS - 
    ":where(.css-dev-only-do-not-override-2i2tap).ant-switch"

    Here you can see, it is denoted as -dev-only meaning, that it will be working fine & can be overriden if written as, 

    :where(.css-dev-only-do-not-override-2i2tap).ant-switch {
        background-color: var(--secondaryColor);
    }    

    But, it is only viable during and for development purposes only. When publishing the codebase with overriden stylings like in the above template (i.e build) on
    develop.mv, the custom stylings applied will not be triggered & applied since the above mentioned class used to manipulate the background-color will be nullified
    Smae will happen on when code goes production, since the above stylings are ONLY-FOR-DEVELOPMENT-PURPOSES
*/

.ant-switch.ant-switch-checked {
    background-color: var(--secondaryColor) !important;
}

.react-datetime-picker__wrapper {
    border-radius: 10px;
    padding: 1px 5px;
    background-color: #fbfbfb;
    border: 1px solid lightgray;
}

.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
    background-color: var(--secondaryColor) !important;
}

/* Device Name */
.deviceName {
    font-family: Arial;
    font-weight: bold;
    color: #333333;
    font-size: 1.4rem;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3); /* Subtle shadow effect for depth */
}

/* Parameter Name */
.parameterName {
    font-family: Arial;
    font-weight: normal;
    color: #666666;
    margin-left: 0.2rem;
}

/* Time Frame Period */
.timeFrame {
    font-family: Arial;
    font-weight: normal;
    color: #666666;
    margin-left: 0.2rem;
}

.timeFrame::after {
    content: '';
    display: block;
    width: 100%; /* Full width */
    height: 1px; /* Underline thickness */
    background-color: #666666; /* Underline color */
}
  
/* .parameterRangeTitle {
    text-decoration: underline;
} */

.modal-dialog.modal-l .modal-content {
    border-radius: 1rem !important;
}


.modal-title,
.btn-close {
    margin: 0px 4px;
}

.modal-overlay {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px); 
    z-index: 1000; 
}
  

/* Calendar START: */

.react-calendar {
    padding: 2px 10px 10px 10px;
    margin: 3px;
    border-radius: 10px;
}

.react-calendar__tile:hover {
    border-radius: 0px;
}

.react-calendar__tile--active {
    border-radius: 6px;
}

.react-calendar__tile--active:hover {
    border-radius: 6px;
}

.react-calendar__month-view__days {
    border: 1px solid #eaeaea;
}

/* Calendar END: */

.ant-switch .ant-switch-handle::before {
    height: 16px !important;
}

.ant-switch.ant-switch-checked .ant-switch-inner .ant-switch-inner-checked {
    margin-top: -2px !important;
}

.antdSwitchCrossBtn {
    padding-bottom: 0.2rem;
    font-size: 0.9rem;
}

.filterPeriodToggle {
    height: 20px;
}

/* .css-dev-only-do-not-override-2i2tap .ant-switch .ant-switch-handle::before {
    height: 3vh;
} */

.parameterDropdown .ant-select-selection-item,
.chartPeriodDropdwon .ant-select-selection-item,
.graphTypeDropdwon .ant-select-selection-item {
    /* color: var(--secondaryColor) !important; */
    color: #3a811d !important;
    font-weight: 600;
}