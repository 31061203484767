
.parameterNameAndSymbol{
  color: #666666;
  font-weight: 800;
  text-transform: capitalize;
  font-size: 0.95rem;
  margin: 0rem;
  margin-left: 0.3rem;
}

.enableAlertsForParam {
  font-size: 0.95rem;
}

.setting-popup {
  background-color : white;
  margin-top: 1rem;
  margin-bottom: 1rem;
  /* border: 1px solid var(--primaryColor);
  box-shadow: 1px 1px 3px 1px var(--primaryColor); */
}

.PfcSettingsAndAlertsMessage {
  height: 88vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 66vw;
}

.paragraph{
  padding-top: 2rem;
  font-size: 1rem;
  margin: 0rem 1rem 1.5rem 1rem;
  text-align: center;
  font-weight: 800;
  font-family: sans-serif;
}

.checkbox{
  font-family: sans-serif;  
  font-weight: 600;
  margin-left: 0.6rem;
}

.checkicon {
  margin-right: 0.3rem;
}

.row .update-btn {
  background-color: var(--primaryColor);
  width: 50%;
  border-color: var(--primaryColor);
  margin: 1rem;
  color: white;
  border-radius: 5px;
  padding:0.4rem;
  font-size: 1.2rem;
} 

.row .update-btn:hover {
  background-color: var(--primaryColor);
  border-color: var(--primaryColor);
}

.row .addbutton{
  height: 2rem;
  margin-bottom: 2rem;
}

.textfields .devicename {
  width: 100%;
  border-color: var(--primaryColor);
} 
  
.left {
    text-align: left;
}

.notification-parameter{
    width: 90%;
    margin : 5rem;
}
   
.navbarcolor 
{
    background-color :black;
}
  
.navbrandcolor {
    color: var(--primaryColor);
}
  
.tabular{
    margin-bottom: 2rem;
    margin-top: 0.3rem;
    /* margin-left: 0.2rem; */
}

.innertab1{
    text-align: left;
}

.singleCheckElement{
 margin: 1rem;
}

.textspan{
    margin-left: 0.3rem;
}
  
.textspanmain{
  color: #666666;
  margin-left: 0.5rem;
} 

.innertabmain{
  font-weight: 900;
  height: 2rem;
  text-align: left;
  margin-top:1rem; 
  font-size:1.1rem; 
}


/* ///////////////////////////          TABLE CSS           /////////////////////////////////////// */


tbody tr:nth-child(odd)
{
  background-color: rgb(213, 243, 190);
}

.alertrange
{
  /* width: 100%; */
  height: 32rem;
  overflow: auto;
}


.settingrange
{
  height: 32rem;
  overflow-y: scroll;
}

.range-btn{
    background-color: var(--primaryColor);
    width: 50%;
    border-color: var(--primaryColor);
    margin:0.5rem 1rem 1rem 1rem;
    color: white;
    border-radius: 5px;
    padding:0.4rem;
    font-size: 1.2rem;
}

.rangetable {
  border: none; 
  margin-top: 1rem;
}

.tableHeadAlign{
  text-align: center;
}

.rangesettinginputtab {
  width: 4rem;
  text-align: center;
  color: black;
}

.rangesettingonlyoneinputtab {
  width: 4rem;
  text-align: center;
}

.rangesettingparameter {
  border: 1px solid var(--primaryColor);
  margin-top: 1rem;
  border-radius: 1rem;
  margin-right: 0.5rem;
}

.ant-slider .ant-slider-rail {
  background-color: #c8c8c8 !important;
}

.ant-slider .ant-slider-handle::after {
  background-color: var(--secondaryColor) !important;
  box-shadow: 0 0 0 2px var(--secondaryColor) !important;
}

.ant-slider .ant-slider-dot {
  width: 9px !important;
  height: 9px !important;
  border: 2px solid lightgray !important;
}

.ant-slider .ant-slider-track {
  /* box-shadow: 0 0 0 1px var(--secondaryColor); */
  background-color: var(--secondaryColor) !important;
}

.ant-slider:hover .ant-slider-track {
  background-color: var(--secondaryColor) !important;
}

.ant-slider-track {
  background-color: var(--secondaryColor) !important;
}

/* Slider Pointer -------> */
/* .slider-enabled::after {
  background-color: var(--secondaryColor) !important;
  box-shadow: 0 0 0 2px var(--secondaryColor) !important;
}

.slider-disabled::after {
  background-color: transparent !important;
} */

/* .slider-enabled:hover::after {
  box-shadow: 0 0 0 4px var(--secondaryColor) !important;
  background-color: var(--secondaryColor) !important;
}

.slider-enabled:focus::after {
  box-shadow: 0 0 0 4px var(--secondaryColor) !important;
} */

/* <------- */

.ant-slider-horizontal .ant-slider-track {
  height: 6px !important;
  margin-top: -1px !important;
}

.ant-slider .ant-slider-handle:hover::after {
  box-shadow: 0 0 0 4px var(--secondaryColor) !important;
}
.ant-slider:hover .ant-slider-handle::after {
  box-shadow: 0 0 0 1px gray !important;
}

.ant-slider .ant-slider-handle:focus::after {
  box-shadow: 0 0 0 4px var(--secondaryColor) !important;
}

.updateButton {
  width: 25%; 
  height: 35px; 
  background-color: #4b51b9; 
  color: white; 
  border: 1px solid gray;
  transition: transform .4s;
}

.updateButton:hover {
  box-shadow: 5px 5px 5px lightgray;
  transform: scale(1.01); 
}

.resetButton {
  width: 8.5rem;
  height: 25px;
  font-size: 0.8rem;
  /* background-color: rgb(176, 176, 176); */
  background-color: #4b51b9;
  color: white;
  border: 1px solid lightgray;
  transition: transform .2s;
}

.resetButton:hover {
  box-shadow: 5px 5px 5px #e2e2e2;
  transform: scale(0.98);  
}





.valueBasedSlider {
  width: 95%; 
  margin-left: 20px;
}
.valueBasedInput,
.reverseSliderInput {
  color: black; 
  width: 12%; 
  margin-top: -1.1rem;
}


.rangeBasedSlider {
  width: 70%;
}
.minMaxInput {
  color: black;
  width: 5rem;
}

.DLVSlider {
  width: 95%; 
  margin-left: 20px;
}

.ant-switch .ant-switch-handle::before {
  height: 16px !important;
}

.DLVSlider > .ant-slider-handle.ant-slider-handle-2 {
  display: none !important;
}


@media (max-width: 1150px) {
  .valueBasedInput,
  .reverseSliderInput {
    width: 7rem; 
  }
}

@media (max-width: 550px) {

  .PfcSettingsAndAlertsMessage {
    height: 88vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
  }

  .updateButton {
    width: 50%;
    height: 30px;
    font-size: 0.7rem;
  }

  .textspanmain {
    font-size: 0.6rem;
  }

  .resetButton {
    font-size: 0.6rem;
    height: 25px;
    width: 60%;
  }
}