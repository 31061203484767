.apiApp {
  display: flex;
  width: 100%;
  align-items: stretch;
  color: #fff;
  background-color: var(--primaryColor);
  margin-right: 3rem;

}

.MainMenuContentLabels{
  font-weight: bolder;
  color: var(--secondaryColor);
}

.SubMenuContentLabels{
font-weight: bold;
color: var(--primaryColor);
}

.btnGet{
background-color: #4CAF50; /* Green */
border: none;
color: white;
padding-bottom: 0rem;
text-align: center;
text-decoration: none;
font-size: 12px;
}

.btnPost{
background-color: var(--hoverLinkColor);
border: none;
color: white;
padding-bottom: 0rem;
text-align: center;
text-decoration: none;
font-size: 12px;
}

.para{
text-align: left;
align-content: center;
color: grey;
margin-left: 1rem;
text-align: justify;
margin-right: 0rem;
}

.apiJson{
background-color: #fff;
color: #212F3D;
margin: 0.5rem;
border-radius: 5px;
font-size: small;
line-height: 1rem;
width: 97%;
overflow-x: scroll;
}

.jsonPre {
color: #212F3D;
padding-top: 1rem;
padding-right: 1rem;
padding-left: 1rem;
}

.ApiKeyButton{
border-radius: 5px;
width: 25%;
background-color: var(--primaryColor);
padding-left: 0rem;
color: white;
margin-left: 1rem;
margin-bottom: 1rem;
margin-right: 1rem;
box-shadow: 2px 3px #888888;
cursor: pointer;

}
.ApiKeyButtonDisabled{
border-radius: 5px;
width: 25%;
background-color: #cccccc;
padding-left: 0rem;
color: #666666;
margin-left: 1rem;
margin-bottom: 1rem;
margin-right: 1rem;
box-shadow: 2px 3px #888888;
cursor: not-allowed;
}

/* Button while being clicked (only active moment) */
.ApiKeyButton:active {
transform: scale(0.95); /* Slight shrink effect on click */
background-color: #218838; /* Darken background color on click */
}

.DeactivateBtn{
border-radius: 5px;
width: 25%;
background-color: var(--secondaryColor);
color: white;
margin-left: 1rem;
margin-bottom: 1rem;
margin-right: 1rem;
box-shadow: 2px 3px #888888;
}

.ActivateBtn{
border-radius: 5px;
width: 25%;
background-color: var(--signalLevel3Color);
color: white;
margin-left: 1rem;
margin-bottom: 1rem;
margin-right: 1rem;
box-shadow: 2px 3px #888888;
}

.apiDocContent{
margin-top: 0rem;
margin-right: 1rem;
width: 100%;
box-shadow: 0rem 0.1rem 0.3rem var(--primaryColor);
}

.innerDocLabels{
border-bottom: 1px var(--primaryColor);
}

.apiMenus{
  align-content: stretch;    
  margin-left: 0rem;
  background-color: var(--primaryColor);
}

.apiSidebar{
margin-right: 0rem; 
margin-left: 0rem;
width: 100%;   
}

.sidebar-header {
padding-top: 0.6rem;
padding-bottom: 0.3rem;
color: #fff;
background-color: var(--primaryColor);
font-weight: bold;
}

.apiNavlink{
color: var(--primaryColor);
padding: 0.2rem;
margin-left: 0rem;
margin-right: 1rem;
font-size: small;
font-weight: bold;
width: 100%;
text-decoration: none;
}

.apiNavlinksub{
color: var(--primaryColor);
padding: 0.2rem;
margin-left: 0rem;
margin-right: -5rem;
font-size: small;
font-weight: bold;
width: 100%;
text-decoration: none;
}

.apiNavlinksub:hover{
background-color: var(--secondaryColor);
color: #fff;
}

.apiNavlink:hover {
background-color: var(--secondaryColor);
color: #fff;
}

.apiNavlinkActive{
 background-color: orange;
 color: #fff;
 font-size: larger;
 font-weight: bold;
}

.menuNavbar{
margin-right: 0.5rem;
background-color: white;
}


.side-menu {
text-align: left;
width: 100%;
height: 100%;
font-size: small;
background-color: #fff;
}

.selectOrd{
width: 40%;
}

.apikeyinput {
overflow-x: auto;
width: 90%;
}

.apikeyinputdisabled {
overflow-x: auto;
background-color: #cccccc;
width: 95%;
}

@media(max-width: 550px){

.apikeyinput {
  overflow-x: auto;
  width: 80%;
}

.apiDocContent{
  margin-right: 1rem;
  width: 80%;
  box-shadow: 0rem 0.1rem 0.3rem var(--primaryColor);
}

.apiJson{
  background-color: #fff;
  color: #212F3D;
  margin: 0.5rem;
  border-radius: 5px;
  font-size: small;
  line-height: 1rem;
  width: 97%;
  overflow-x: scroll;
}

}

@media(max-width: 900px){

.apikeyinput {
  overflow-x: scroll;
  width: 92%;
}

.apiDocContent{
  margin-right: 1rem;
  width: 100%;
  box-shadow: 0rem 0.1rem 0.3rem var(--primaryColor);
}

.apiJson{
  background-color: #fff;
  color: #212F3D;
  margin: 0.5rem;
  border-radius: 5px;
  font-size: small;
  line-height: 1rem;
  width: 93%;
  overflow-x: scroll;
}

}

@media(max-width: 850px){

.apikeyinput {
  overflow-x: scroll;
  width: 92%;
}

.apiDocContent{
  margin-right: 1rem;
  width: 100%;
  box-shadow: 0rem 0.1rem 0.3rem var(--primaryColor);
}

.apiJson{
  background-color: #fff;
  color: #212F3D;
  margin: 0.5rem;
  border-radius: 5px;
  font-size: small;
  line-height: 1rem;
  width: 90%;
  overflow-x: scroll;
}

}

@media(max-width: 830px){

.apikeyinput {
  overflow-x: scroll;
  width: 90%;
}

.apiDocContent{
  margin-right: 1rem;
  width: 100%;
  box-shadow: 0rem 0.1rem 0.3rem var(--primaryColor);
}

.apiJson{
  background-color: #fff;
  color: #212F3D;
  margin: 0.5rem;
  border-radius: 5px;
  font-size: small;
  line-height: 1rem;
  width: 87%;
  overflow-x: scroll;
}

}
@media(max-width: 730px){

.apikeyinput {
  overflow-x: scroll;
  width: 90%;
}

.apiDocContent{
  margin-right: 1rem;
  width: 100%;
  box-shadow: 0rem 0.1rem 0.3rem var(--primaryColor);
}

.apiJson{
  background-color: #fff;
  color: #212F3D;
  margin: 0.5rem;
  border-radius: 5px;
  font-size: small;
  line-height: 1rem;
  width: 80%;
  overflow-x: scroll;
}

}
@media(max-width: 690px){

.apikeyinput {
  overflow-x: scroll;
  width: 85%;
}

.selectOrd{
  width: 40%;
}

.apiDocContent{
  margin-right: 1rem;
  width: 100%;
  box-shadow: 0rem 0.1rem 0.3rem var(--primaryColor);
}

.apiJson{
  background-color: #fff;
  color: #212F3D;
  margin: 0.5rem;
  border-radius: 5px;
  font-size: small;
  line-height: 1rem;
  width: 75%;
  overflow-x: scroll;
}

}