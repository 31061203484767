.header {
    position: relative;
    text-align: center;
    background: linear-gradient(60deg, rgba(0, 172, 193, 1) 100%, rgba(0, 172, 193, 1) 100%);
    color: white;
    border: 1px black;
}

.waves {
    position: relative;
    width: 100%;
    height: 2vh;
    margin-bottom: -1px;
    /*Fix for safari gap*/
    min-height: 25px;
    max-height: 30px;
}

/* Animation */

.parallax>use {
    animation: move-forever 25s cubic-bezier(.55, .5, .45, .5) infinite;
}

.parallax>use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
}

.parallax>use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
}

.parallax>use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
}

.parallax>use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
}

@keyframes move-forever {
    0% {
        transform: translate3d(-90px, 0, 0);
    }

    100% {
        transform: translate3d(85px, 0, 0);
    }
}

/*Shrinking for mobile*/
@media (max-width: 768px) {
    .waves {
        height: 40px;
        min-height: 40px;
    }

    .content {
        height: 30vh;
    }

    h1 {
        font-size: 24px;
    }
}

/* --------------------------------------------------------------------------------- */
.dvcTreeRefreshBtn {
    color: white;
    background-color: var(--primaryColor);
    /* margin-left: 2rem;  */
    font-size: 1rem;
    border-radius: 5px;
    justify-content: center;
    /* margin-bottom: 3rem;  */
}

.dvcSanitationBtn {
    color: white;
    background-color: var(--primaryColor);
    /* margin-left: 2rem;  */
    font-size: 0.8rem;
    border-radius: 5px;
    justify-content: center;
    /* margin-top: 0.3rem; */
    font-weight: bold;
    /* margin-bottom: 3rem;  */
}

.upDownBtn {
    color: black;
    font-size: 1rem;
    justify-content: center;
    font-weight: bold;
}

.dvcTreeRefreshBtn:hover {
    background-color: var(--secondaryColor);
    border: 2px solid var(--secondaryColor);
}

.dvcSanitationBtn:hover {
    background-color: var(--secondaryColor);
    border: 2px solid var(--secondaryColor);
}

.upDownBtn:hover {
    background-color: var(--secondaryColor);
}

.tooltipForGoogleFromBtn {
    /* position: relative; */
    /* display: inline-block; */
    color: var(--hoverLinkColor);
    cursor: pointer;
    font-size: 1rem;
}

.tooltipForGoogleFromBtn .tooltipTextForGoogleFromBtn {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.ParamsValueMissingOrWithoutRangeForToiletDash {
    color: rgb(33, 31, 31);
    font-size: 13px;
    /* font-size: 2.2rem;
    font-weight: 800; */
}

.parameterOuterDivWithoutRangeForToiletDash {
    background-color: white;
    /* box-shadow: 1px 1px 5px 2px grey;  */
    box-shadow: grey 0px 1px 4px;

    /* border-radius: 5px; */
}

.parameterOuterDivWithoutRangeForToiletDash:hover {
    background-color: rgb(237, 236, 236);
    transform: translateY(1px);
}

.ParamsValueRangeLowVPoorForToiletDash {
    color: #FF0000;
    font-size: 13px;
    /* font-weight: 800; */
}
.ParamsValueRangeSevereForToiletDash {
    color: #C00000;
    font-size: 13px;
    /* font-weight: 800; */
}

.ParamsValueRangeGoodForToiletDash {
    color: #00b050;
    font-size: 13px;
    /* font-size: 2.2rem; */
    /* font-weight: 800; */
}

.ParamsValueRangeSatisfactoryForToiletDash {
    color: #90b050;
    font-size: 13px;
}



.parameterOuterDivRangeSevereForToiletDash {
/* box-shadow: 1px 1px 5px 2px #C00000; */
box-shadow: #C00000 0px 1px 4px;

/* border-radius: 5px; */
background-color: #c000001c;
}
.parameterOuterDivRangeLowVPoorForToiletDash {
/* box-shadow: 1px 1px 5px 2px #C00000; */
box-shadow: #FF0000 0px 1px 4px;

/* border-radius: 5px; */
background-color: #FF00001c;
}

.wlidiv:hover {
    transform: translateY(1px);
}

.parameterOuterDivRangeSevereForToiletDash:hover {
    background-color: #e9bcbce6;
    transform: translateY(1px);
}
.parameterOuterDivRangeLowVPoorForToiletDash:hover {
    background-color: #ffbcbce6;
    transform: translateY(1px);
}

.parameterOuterDivRangeGoodForToiletDash {
    /* box-shadow: 1px 1px 5px 2px #00b050; */
    /* box-shadow: 2px 1px 3px 1px #00b050; */
    /* box-shadow: #00b050 0px 1px 4px, #00b050 0px 0px 0px 3px; */
    /* box-shadow:  #00b050 0px 3px 8px; */
    /* box-shadow: #00b050 0px 2px 5px 0px; */
    /* box-shadow:#00b050 0px 10px 50px; */
    box-shadow: #00b050 0px 1px 4px;

    /* border-radius: 5px; */
    background-color: #00b05014;
}

.parameterOuterDivRangeGoodForToiletDash:hover {
    background-color: #e1f5e1;
    transform: translateY(1px);
}

.parameterOuterDivRangeSatisfactoryForToiletDash {
    box-shadow: #90b050 0px 1px 4px;
    background-color: #90b05020;
}

.parameterOuterDivRangeSatisfactoryForToiletDash:hover {
    background-color: #90b05054;
    transform: translateY(1px);
}

.ParamsValueRangeModerateForToiletDash {
    color: gold;
    font-size: 13px;
    /* font-size: 2.2rem; */
    /* font-weight: 800; */
}

.parameterOuterDivRangeModerateForToiletDash {
    /* border-right: 5px solid rgb(255, 255, 8);
    border-bottom: 5px solid rgb(255, 255, 8);
    border-left: 2.5px solid rgb(255, 255, 8);
    border-top: 2.5px solid rgb(255, 255, 8); */
    /* box-shadow: inset 0.5px 0.5px 1px 1px lightgray, 1px 1px 2px 2px lightgray; */
    box-shadow: gold 0px 1px 4px;


    /* border-radius: 5px;  */
    background-color: #ffff000d;
}

.parameterOuterDivRangeModerateForToiletDash:hover {
    background-color: #f1f1e2c1;
    transform: translateY(1px);
}

.ParamsValueRangePoorForToiletDash {
    color: orange;
    font-size: 13px;
    /* font-size: 2.2rem; */
    /* font-weight: 800; */
}

.parameterOuterDivRangePoorForToiletDash {
    /* box-shadow: 1px 1px 5px 2px orange; */
    box-shadow: rgb(207, 142, 23) 0px 1px 4px;

    /* border-radius: 5px; */
    background-color: #ffa5000d;
}

.parameterOuterDivRangePoorForToiletDash:hover {
    background-color: #ece2cce4;
    transform: translateY(1px);
}

.ParamsValueRangeVeryPoorForToiletDash {
    color: red;
    font-size: 13px;
    /* font-size: 2.2rem; */
    /* font-weight: 800; */
}

.parameterOuterDivRangeVeryPoorForToiletDash {
    /* box-shadow: 1px 1px 5px 2px red; */
    box-shadow: red 0px 1px 4px;

    /* border-radius: 5px; */
    background-color: #ff00000f;
}

.parameterOuterDivRangeVeryPoorForToiletDash:hover {
    background-color: #e7a2a2ba;
    transform: translateY(1px);
}

.ParamsValueRangePFCLadiesForToiletDash {
    color: pink;
    font-size: 13px;
}

.parameterOuterDivRangePFCLadiesForToiletDash {
    /* color:pink; */
    box-shadow: pink 0px 1px 4px;
    /* background-color: #FDF7F9; */

    /* background-color: #FFF7FA */
    /* background-color: #FAEAF6; */
    background-color: #FAEBF7;

}

.parameterOuterDivRangePFCLadiesForToiletDash:hover {
    background-color: #f5efeff0;
    transform: translateY(1px);
}

.ParamsValueRangePFCGentsForToiletDash {
    color: skyblue;
    font-size: 13px;
}

.parameterOuterDivRangePFCGentsForToiletDash {
    /* color:skyblue; */
    box-shadow: skyblue 0px 1px 4px;
    /* background-color: #F7FFFF; */
    background-color: #EAFAFA;


}

.parameterOuterDivRangePFCGentsForToiletDash:hover {
    background-color: #d4f3f3;
    transform: translateY(1px);
}

@media (max-width: 1370px) {
    .dvcSanitationBtn {
        color: white;
        background-color: var(--primaryColor);
        /* margin-left: 2rem;  */
        font-size: 0.8rem;
        border-radius: 5px;
        justify-content: center;
        /* margin-top: 0.3rem; */
        font-weight: bold;
        /* margin-bottom: 3rem;  */
    }

    .upDownBtn {
        color: black;
        font-size: 0.8rem;
        justify-content: center;
        font-weight: bold;
    }
}

@media (max-width: 768px) {
    .dvcSanitationBtn {
        color: white;
        background-color: var(--primaryColor);
        /* margin-left: 2rem;  */
        font-size: 0.6rem;
        border-radius: 5px;
        justify-content: center;
        /* margin-top: 0.3rem; */
        font-weight: bold;
        /* margin-bottom: 3rem;  */
    }

    .upDownBtn {
        color: black;
        font-size: 0.6rem;
        justify-content: center;
        font-weight: bold;
    }
}

@media (max-width: 550px) {
    .dvcSanitationBtn {
        color: white;
        background-color: var(--primaryColor);
        /* margin-left: 2rem;  */
        font-size: 0.6rem;
        border-radius: 5px;
        justify-content: center;
        /* margin-top: 0.3rem; */
        font-weight: bold;
        /* margin-bottom: 3rem;  */
    }

    .upDownBtn {
        color: black;
        font-size: 0.6rem;
        justify-content: center;
        font-weight: bold;
    }
}

/* @media (max-width: 1370px) {

    .ParamsValueMissingOrWithoutRangeForToiletDash{
        color: rgb(33, 31, 31);
        font-size: 1.8rem;
        font-weight: 800;
    }

    .ParamsValueRangeSevereForToiletDash{
        color:#C00000;
        font-size: 1.8rem;
        font-weight: 800;
    }

    .ParamsValueRangeGoodForToiletDash{
        color: #00b050;
        font-size: 1.8rem;
        font-weight: 800;
    }

    .ParamsValueRangeModerateForToiletDash{
        color: gold;
        font-size: 1.8rem;
        font-weight: 800;
    }  

    .ParamsValueRangePoorForToiletDash{
        color:orange;
        font-size: 1.8rem;
        font-weight: 800;
    }

    .ParamsValueRangeVeryPoorForToiletDash{
        color:red;
        font-size: 1.8rem;
        font-weight: 800;
    }

}

@media (max-width: 768px) {

    .ParamsValueMissingOrWithoutRangeForToiletDash{
        color: rgb(33, 31, 31);
        font-size: 1.8rem;
        font-weight: 800;
    }

    .ParamsValueRangeSevereForToiletDash{
        color:#C00000;
        font-size: 1.8rem;
        font-weight: 800;
    }

    .ParamsValueRangeGoodForToiletDash{
        color: #00b050;
        font-size: 1.8rem;
        font-weight: 800;
    }

    .ParamsValueRangeModerateForToiletDash{
        color: gold;
        font-size: 1.8rem;
        font-weight: 800;
    }  

    .ParamsValueRangePoorForToiletDash{
        color:orange;
        font-size: 1.8rem;
        font-weight: 800;
    }

    .ParamsValueRangeVeryPoorForToiletDash{
        color:red;
        font-size: 1.8rem;
        font-weight: 800;
    }
}

@media (max-width: 550px) {

    .parameterOuterDivWithoutRangeForToiletDash{
        box-shadow: 1px 1px 5px 2px grey;
        border-radius: 5px;
    }
} */