.InputFormVtDeviceData-blackfilm{
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
  .InputFormDeviceData{
    margin-top: 0.5rem;
    padding: 1rem 1rem 0.2rem 1rem;
    box-sizing: border-box;
    margin-bottom: 1rem;
    background: white;
  
  }
  .InputFormDeviceData-popup{
    margin-top: 1rem;
    padding:0rem;
    box-sizing: border-box;
    margin-bottom: 10rem;
    background: white;
    height: 40rem;
    overflow-y: scroll;

  }
  .DeviceDataBox{
    margin-left: 1rem;
    padding-right: 1rem;
    border: 0.5px solid var(--primaryColor);

  }
  .DeviceDataLabel{
    margin-left: 2rem;
    width: 20%;
    font-size: 0.8rem;
    text-align: left;
  }
  
   .InputFormDeviceData-popup .DeviceData-form{
     margin-bottom: 1.2rem;
   }
   
   .InputFormDeviceData-popup .InputFormDeviceData
   {
    border: 1px solid var(--primaryColor);
    width: 100%;
    border-radius: 5px;
    height: 2.5rem;
    
   }
  
    .DeviceData {
      background-color: var(--primaryColor);
      border-color: var(--primaryColor);
      width: 80%;
      color: white; 
      margin: 1rem 0rem 1.5rem 0rem;
      padding: 0.5rem 1rem;
      border-radius: 0.3rem;
      font-size: 1rem;
      line-height: 1.5;
    }
    .InputFormDeviceData-popup.deviceDetail{
      margin-top: 1rem;
      margin-bottom: 1rem;
      font-size: 1.5rem;
      margin-left: 0rem;
      text-align: center;
      font-weight: 700;
      padding-top: 1rem
    } 

    .DeviceDataLabelid{
      width: 20%;
      text-align: left;
      margin-left: 2rem;
      font-size: 0.8rem;
      
    }

    .DeviceDataBoxid{
      margin-left:1rem;
      width: 100%;
    }
    
    