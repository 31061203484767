.drFirstmodalslide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80vh;
    padding-right: 1.4rem;
    padding-left: 1.2rem;
    background-color: var(--primaryColor);
  }

  .drSecondmodalslide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
    width: 100%;
    height: 80vh;
    padding-left: 1rem;
    padding-right: 1.5rem;
    background-color: var(--primaryColor);
  }

  .drThirdmodalslide {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: left;
    width: 100%;
    height: 80vh;
    padding: 1rem;
    background-color: var(--primaryColor);
  } 

  .drFourthmodalslide {
    display: flex;
    justify-content: center;
    /* align-items: center;  */
    flex-direction: column;
    text-align: center;
    width: 100%;
    height: 80vh;
    padding: 1rem;
    background-color: var(--primaryColor);
  } 