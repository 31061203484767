.borderless-select .ant-select-selector {
  border: none !important;
  box-shadow: none !important;
}

.borderless-select .ant-select-selection-search-input {
  border: none !important;
  box-shadow: none !important;
}

.borderless-select .ant-select-arrow {
  display: none !important;
}

/* styles.css */
/* .SearchBlock {
    position: relative;
} */

/* .search-container {
    position: relative;
    width: 250px;
  } */

  .mobileViewSearchBoxInput {
    width: 100%;
    border: none;
    padding: 2px 5px;
  }
  
  .searchBoxInput {
    width: 100%;
    padding: 0px 5px;
    box-sizing: border-box;
  }

  .mobileViewdropdown {
    margin: 1px 0px 0px 0px;
    border-bottom-left-radius: 0.8rem;
    border-bottom-right-radius: 0.8rem;
    box-shadow: 2px 2px 10px lightgray;
    max-height: 40vh;
    top: 100%;
    left: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-top: none;
    overflow-y: auto;
    z-index: 1000;
    width: 97vw; /* Adjust width as a percentage */
  }
  
  .desktopViewDropdown {
    margin: 7px 0px 0px -7px;
    padding: 0.2rem;
    border-bottom-left-radius: 0.8rem;
    border-bottom-right-radius: 0.8rem;
    box-shadow: 2px 2px 10px lightgray;
    max-height: 40vh;
    top: 100%;
    left: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-top: none;
    overflow-y: auto;
    z-index: 1000;
    width: 72vw; /* Adjust width as a percentage */
    /* max-width: 93vw; Ensure it doesn't exceed viewport width */
  }
  
  .dropdown-option {
    /* border-bottom: 1px solid #dbdbdb; */
    padding: 8px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 0.8rem;
  }

  .dropdown-title {
    display: flex;
    border-bottom: 1px solid lightgray;
    justify-content: space-between;
    align-items: center;
    font-size: 11px;
    color: #b2b2b2;
  }
  
  .dropdown-option:hover {
    background-color: #f0f0f0;
  }

  .clear-button {
    position: absolute;
    right: 10px;
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 1.2em;
    color: #999;
  }
  
  .clear-button:hover {
    color: #666;
  }

.toiletNodeIcon {
  color: green;
  font-size: 1.2rem;
  margin-left: 2px;
  margin-right: 7px;
}

.deviceIcon {
  margin-left: 2px;
  font-size: 1.1rem;
  color: green;
  margin-right: 4px;
}

