.ReactTableStyle{
    border-Radius: 12px;
    border-color: gray;
    padding: 0.2rem;
}

.resizer {
    background: transparent;
    width: 10px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(50%);
    z-index: 1;
    touch-action:none;
}

.isResizing {
    background: transparent;
}

.table {
    width: 100%;
    border-spacing: 0;
    display: inline-block;
    border: 1px solid grey;
    /* border: rgb(189, 56, 56); */
}

.tableWrap {
    display: block;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    /* border-bottom: 1px solid black; */
}
    
.td {
    border-bottom: 0;
    border-right: 1px solid rgba(0,0,0,.07);
}

.tr {
    border-bottom: 0;
}

.tbody>div:nth-child(even) {
    background-color: rgba(0,100,1,0.5)
}
   
.tbody>div:nth-child(odd) {
    background-color: rgba(0,100,1,0.3)
}

/* .tr:nth-child(even) {
    background-color: #D0E0F6;

} */

.tr:nth-child(even) {
    /* background-color: #E9F7EF; */
    background-color:white;


}

.tr:nth-child(even):hover {
    /* background-color: #F2F2F2; */
    background-color: #DEF1FF;
}

/* .tr:nth-child(odd) {
    background-color: #B6C8DF;
} */

.tr:nth-child(odd) {
    /* background-color: #EBF5FB; */
    /* background-color: #E9F7EF; */
    background-color: #F2F4F4;


    
}

.tr:nth-child(odd):hover {
    /* background-color: #F2F2F2; */
    background-color: #DEF1FF;
}




/* "Alerts" Sub-Tab START: --------> */

/* .tBodyContent > tr:nth-child(even) {
    background-color: #f0d8ac;
} */

/* .tBodyContent > tr:nth-child(odd) {
    background-color: #f1cc88;
} */

/* .trBodyRow:nth-child(even) {
    background-color: #f0d8ac;
} */

/* .trBodyRow:nth-child(odd) {
    background-color: #f1cc88;
} */

/* .tBodyContent > tr:nth-child(even):hover,
.tBodyContent > tr:nth-child(odd):hover {
    background-color: rgb(239, 239, 239);
} */

/* "Alerts" Sub-Tab END: <-------- */


/* / "Alerts" Sub-Tab START: --------> / */

.tBodyContent > tr:nth-child(even) {
    background-color: #FEF5E7; 
    background-color: #fffbf3;
}

.tBodyContent > tr:nth-child(odd) {
    background-color: #FDEBD0;
    background-color: #fff6e5;
}

/* .trBodyRow:nth-child(even) {
    background-color: #f0d8ac;
} */

/* .trBodyRow:nth-child(odd) {
    background-color: #f1cc88;
} */

.tBodyContent > tr:nth-child(even):hover,
.tBodyContent > tr:nth-child(odd):hover {
    background-color: rgb(239, 239, 239);
    background-color: #FAD7A0;
}

/* / "Alerts" Sub-Tab END: <-------- / */








.rt-resizable-header-content {
    overflow: hidden;
    text-overflow: ellipsis;
}

.trforHeader {
    border-bottom: 0;
    background-color: white;
    /* background-color: #374782; */

    border-radius: "12px";
}

.tdForHeader {
    margin: 0;
    border-bottom: 2px solid rgba(0,0,0,.1);
    border-right: 1px solid rgba(0,0,0,.07);
    position: relative;  
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;  
    line-height: normal;
    padding-top: 7px;
    padding-bottom: 7px;
    /* background-color: #5e72bb; */
    /* background-color: #374782; */
    /* color:white; */

}

.Header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.fa {
    padding-left: 0.5rem;
}

.trforSearchField {
    border-bottom: 0;
    background-color: rgb(247, 249, 250);
}

.tdForSearchField {
    margin: 0;
    padding: 0.3rem;
    border-bottom: 1px  solid white; 
    border-right: 1px solid rgba(0,0,0,.07);
    position: relative;
}

.th, 
    .td {
        margin: 0;
        padding: 0.5rem;
        border-bottom: 1px  solid white; 
        border-right: 1px solid rgba(0,0,0,.07);
        position: relative;
}

/* .td:last-child {
    border-right: 0;
} */

.onRowSelection {
    cursor: default;
    background: #6c95ba;
    color: white;
    align-items: center;
}

/* .tr:nth-child(even):: {
    background-color: black;

} */

/* .AlertLogTableHeader {
    font-size: .9rem;
    font-weight: 600;
}

.rt-resizable-header-content:after{
    position: absolute;
    top: 3px;
    height: 18px;
    width: 18px;
    color: transparent;
    content: ".";
} */

.-pagination{
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 5px 10px; 
    margin-top: 0.2rem;
    border: 1px solid #cfcfcf;
    border-radius: 1rem;
    /* box-shadow: 0 0 15px 0 rgba(0,0,0,.1);
    border-top: 2px solid rgba(0,0,0,.1); */
}

.-previous {
    flex: 1 1;
    text-align: center;
}

.-btn{
    -webkit-appearance: none; 
    appearance: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 3px;
    padding: 6px;
    font-size: 1em;
    color: rgba(0,0,0,.6);
    background: rgba(0,0,0,.1);
    transition: all .1s ease;
    cursor: pointer;
    outline-width: 0;
}

.-btn[disabled] {
    opacity: .5;
    cursor: not-allowed;
}

.-btn:not(:disabled):hover {
    background:#B2B2B2;
}

.-center{
    flex: 1.5 1;
    text-align: center;
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}

.-pageInfo{
    display: inline-block;
    margin: 3px 10px;
    white-space: nowrap;
}

.-pageSizeOptions{
    margin: 3px 10px;
}

.select-wrap {
    border: 1px solid rgba(0,0,0,.1);
    background: #fff;
    /* padding: 5px 7px; */
    font-size: inherit;
    border-radius: 3px;
    font-weight: 400;
    outline-width: 0; 
}

.-next{
    flex: 1 1;
    text-align: center;
}

.-pageJump {
    display: inline-block;
    padding-right: 0.2rem;
}

.-pageJump input{
    width: 70px;
    text-align: center;
    height: 28px;
}

.rt-tr{
    text-align: center;
    display: inline-flex;
    flex: 1 0 auto;
}

.rt-th{
    line-height: normal;
    position: relative;
    box-shadow: transparent 0px 0px 0px 0px inset;
    padding: 5px;
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.table-body-row:hover {
    background-color: #e6e6ff;
}

.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: green !important;
}
  
.searchInputBox {
    transition: transform .4s;       
}

.searchInputBox:hover {
    transform: scale(0.96);
}

.ant-input-number .ant-input-number-handler-wrap {
    border-radius: 1rem !important;
    margin-right: 0.1rem !important;
}
  
.antdPagination{
    border-Top: 2px solid rgba(0,0,0,.1);
}

/* .ant-btn-primary:hover {
    background-color: red;
} */