  
  .middle {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: space-evenly;
  }

  .middle input[type=radio] {
    display: none;
  }

  .middle input[type=radio]:checked + .worsebox {
    background-color: var(--signalLevel1Color);
  }

  .middle input[type=radio]:checked + .goodbox {
    background-color: var(--signalLevel3Color);
  }

  .middle input[type=radio]:checked + .badbox {
    background-color: var(--signalLevel2Color);
    text-shadow: 1px 0px 5px darkgray;
  }

  .middle input[type=radio]:checked + .worsebox span {
    color: white;
    transform: translateY(18px);
  }

  .middle input[type=radio]:checked + .badbox span {
    color: white;
    transform: translateY(18px);
    text-shadow: 1px 0px 5px darkgray;
  }

  .middle input[type=radio]:checked + .goodbox span {
    color: white;
    transform: translateY(18px);
  }

  .middle input[type=radio]:checked + .worsebox span:before {
    transform: translateY(2px);
    opacity: 1;
  }

  .middle input[type=radio]:checked + .badbox span:before {
    transform: translateY(2px);
    opacity: 1;
  }

  .middle input[type=radio]:checked + .goodbox span:before {
    transform: translateY(2px);
    opacity: 1;
  }

  .middle .worsebox {
    width: 5rem; 
    height: 5rem;
    background-color: #fff;
    transition: all 250ms ease;
    will-change: transition;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    position: relative;
    font-family: "Dax", sans-serif;
    font-weight: 900;
    border: 1.5px solid grey;
    border-radius: 50%;
  }

  .middle .badbox {
    width: 5rem;
    height: 5rem;
    background-color: #fff;
    transition: all 250ms ease;
    will-change: transition;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    position: relative;
    font-family: "Dax", sans-serif;
    font-weight: 900;
    border: 1.5px solid grey;
    border-radius: 50%;
  }

  .middle .goodbox {
    width: 5rem;
    height: 5rem;
    background-color: #fff;
    transition: all 250ms ease;
    will-change: transition;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    position: relative;
    font-family: "Dax", sans-serif;
    font-weight: 900;
    border: 1.5px solid grey;
    border-radius: 50%;
  }

  .middle .worsebox:active {
    transform: translateY(10px);
  }

  .middle .badbox:active {
    transform: translateY(10px);
  }

  .middle .goodbox:active {
    transform: translateY(10px);
  }

  .middle .worsebox span {
    position: absolute;
    transform: translate(0, 18px);
    left: 0;
    right: 0;
    transition: all 300ms ease;
    font-size: 0.9rem;
    user-select: none;
    color: var(--signalLevel1Color);
  }

  .middle .badbox span {
    position: absolute;
    transform: translate(0, 18px);
    left: 0;
    right: 0;
    transition: all 300ms ease;
    font-size: 0.9rem;
    user-select: none;
    color: var(--signalLevel2Color);
    text-shadow: 1px 0px 5px gray;
  }

  .middle .goodbox span {
    position: absolute;
    transform: translate(0, 18px);
    left: 0;
    right: 0;
    transition: all 300ms ease;
    font-size: 0.9rem;
    user-select: none;
    color: var(--signalLevel3Color);
  }

  .middle .worsebox span:before {
    font-size: 0.9rem;
    transform: translateY(5px);
    transition: all 300ms ease-in-out;
    font-weight: bold;
    color: white;
  }

  .middle .badbox span:before {
    font-size: 0.9rem;
    transform: translateY(30px);
    transition: all 300ms ease-in-out;
    font-weight: bold;
    color: white;
    text-shadow: 1px 0px 5px gray;
  }

  .middle .goodbox span:before {
    font-size: 0.9rem;
    transform: translateY(30px);
    transition: all 300ms ease-in-out;
    font-weight: bold;
    color: white;
  }

  .ratingPgOuterDiv {

    border: 1px solid var(--primaryColor);
    border-radius: 0.5rem;
    box-shadow: 1px 5px 5px 1px var(--primaryColor);
    margin-top: 1rem;
    /* margin-left: 0.5rem;
    margin-right: 0.5rem; */

  }

  /* Default Screen Styling */

  .avgToiletDataDiv {
    background-color: rgb(248, 248, 248); 
    border-radius: 15px;
    padding: 20px;
    display: flex;
    justify-content: space-evenly;
    margin-left: 15px;
    margin-right: 15px;
  }

  .iconLogo {
    border: solid 2px rgb(108, 142, 154);
    border-radius: 25px;
    width: 150px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .iconLogo img {
    padding-left: 5px;
    padding-right: 5px;
    width: 100px;
    height: 100px;
  }

  .iconTitle {
    margin-top: -10 px; 
    padding-bottom: 10px;
    font-size: 20px; 
    font-weight: bold;  
    padding-left: 8px; 
    padding-right: 8px;
  }

  .odourLine {
    margin-top: 0;
    width: 100%;
    border: solid 2px #14282f;
    border-radius: 0 0 100% 100%;
  }
  
  /* Media Queries for handling different screen resolutions */
  @media  screen and (max-width: 1195px) {
    .avgToiletDataDiv {
      display: flex;
      justify-content: space-evenly;
      margin-left: 15px;
      margin-right: 15px;
    }
    .iconLogo {
      border: solid 2px rgb(108, 142, 154);
      border-radius: 15px;
      width: 120px;
      margin-left: 8px;
      margin-right: 8px;
    }
    .iconLogo img {
      padding-left: 5px;
      padding-right: 5px;
      width: 100px;
      height: 100px;
    }
    .iconTitle {
      font-size: 20px;
    }
  }
  
  @media screen and (max-width: 1080px) {
    .avgToiletDataDiv {
      display: flex;
      justify-content: space-evenly;
      margin-left: 15px;
      margin-right: 15px;
    }
    .iconLogo {
      border: solid 2px rgb(108, 142, 154);
      border-radius: 15px;
      width: 120px;
    }
    .iconLogo:first-child {
      margin-left: 5px;
    }
    .iconLogo img {
      padding-left: 5px;
      padding-right: 5px;
      width: 90px;
      height: 90px;
    }
    .iconTitle {
      margin-left: -8px;
      margin-right: -8px;
      font-size: 20px;
    }
  }

  @media screen and (max-width: 991px) {
    .avgToiletDataDiv {
      margin-left: 30px;
      margin-right: 30px;
    }
    .iconLogo {
     width: 180px;
     border: solid 2px rgb(108, 142, 154);
     border-radius: 15px;
    }
    .iconLogo img {
      width: 60%;
    }
    .iconTitle {
      font-size: 22px;
    }
  }

  @media screen and (max-width: 875px) {
    .avgToiletDataDiv {
      display: flex;
      justify-content: space-evenly;
     /* width: 180px; */

    }
    .iconLogo img {
      width: 70%;
    }
    .iconTitle {
      font-size: 22px;
    }
  }

  @media screen and (max-width: 749px) {
    .avgToiletDataDiv {
      display: flex;
      justify-content: space-evenly;
      margin-left: 10px;
      margin-right: 10px;
    }
    .iconLogo {
      border: solid 2px rgb(108, 142, 154);
      border-radius: 15px;
      width: 140px;
    }
    .iconLogo img {
      width: 90px;
      height: 90px;
      padding-left: 5px;
      padding-right: 5px;
    }
    .iconTitle {
      font-size: 20px;
      margin-left: -20px;
      margin-right: -20px;
  }
}

  @media screen and (max-width: 730px) {
    .avgToiletDataDiv {
      display: flex;
      justify-content: space-evenly;
      margin-left: 10px;
      margin-right: 10px;
    }
    .iconLogo {
      border: solid 2px rgb(108, 142, 154);
      border-radius: 15px;
      width: 140px;
    }
    .iconLogo img {
      width: 90px;
      height: 90px;
      padding-left: 5px;
      padding-right: 5px;
    }
    .iconTitle {
      font-size: 19px;
    }
  }

  @media screen and (max-width: 645px) {
    .avgToiletDataDiv {
      display: flex;
      justify-content: space-evenly;
      margin-left: 10px;
      margin-right: 10px;
    }
    .iconLogo {
      border: solid 2px rgb(108, 142, 154);
      border-radius: 15px;
      width: 140px;
    }
    .iconLogo img {
      width: 90px;
      height: 90px;
      padding-left: 5px;
      padding-right: 5px;
    }
    .iconTitle {
      font-size: 18px;
      margin-left: -20px;
      margin-right: -20px;
    }
  }

  @media screen and (max-width: 611px) {
    .avgToiletDataDiv {
      display: flex;
      justify-content: space-evenly;
      margin-left: 10px;
      margin-right: 10px;
    }
    .iconLogo {
      border: solid 2px rgb(108, 142, 154);
      border-radius: 15px;
      width: 140px;
    }
    .iconLogo img {
      width: 90px;
      height: 90px;
      padding-left: 5px;
      padding-right: 5px;
    }
    .iconTitle {
      margin-left: -20px;
      margin-right: -20px;
      font-size: 17px;
  }
  }

  @media screen and (max-width: 587px) {
    .avgToiletDataDiv {
      display: flex;
      justify-content: space-evenly;
      margin-left: 10px;
      margin-right: 10px;
    }
    .iconLogo {
      border: solid 2px rgb(108, 142, 154);
      border-radius: 15px;
      width: 140px;
    }
    .iconLogo img {
      width: 90px;
      height: 90px;
      padding-left: 5px;
      padding-right: 5px;
    }
    .iconTitle {
      margin-left: -20px;
      margin-right: -20px;
      font-size: 16.5px;
  }
  }

  @media screen and (max-width: 562px) {
    .avgToiletDataDiv {
      display: flex;
      justify-content: space-evenly;
      margin-left: 10px;
      margin-right: 10px;
    }
    .iconLogo {
      border: solid 2px rgb(108, 142, 154);
      border-radius: 15px;
      width: 160px;
    }
    .iconLogo:first-child {
      margin-left: -5px;
    }
    .iconLogo:last-child {
      margin-right: -5px;
    }
    .iconLogo img {
      width: 90px;
      height: 90px;
      padding-left: 5px;
      padding-right: 5px;
    }
    .iconTitle {
      margin-left: -20px;
      margin-right: -20px;
      font-size: 16.5px;
  }
  }

  @media screen and (max-width: 550px) {
    .avgToiletDataDiv {
      display: flex;
      justify-content: space-evenly;
      margin-left: 10px;
      margin-right: 10px;
    }
    .iconLogo {
      border: solid 2px rgb(108, 142, 154);
      border-radius: 15px;
      width: 140px;
    }
    .iconLogo img {
      width: 90px;
      height: 90px;
      padding-left: 5px;
      padding-right: 5px;
    }
    .iconTitle {
      font-size: 15.5px;
      margin-left: -10px;
      margin-right: -10px;
    }
  }

  @media screen and (max-width: 525px) {
    .avgToiletDataDiv {
      display: flex;
      justify-content: space-evenly;
      margin-left: 10px;
      margin-right: 10px;
    }
    .iconLogo {
      border: solid 2px rgb(108, 142, 154);
      border-radius: 15px;
      width: 120px;
    }
    .iconLogo img {
      width: 90px;
      height: 90px;
      padding-left: 5px;
      padding-right: 5px;
    }
    .iconTitle {
      font-size: 17px;
    }
  }

  @media  screen and (max-width: 473px) {
    .avgToiletDataDiv {
      display: flex;
      justify-content: space-evenly;
      margin-left: 10px;
      margin-right: 10px;
    }
    .iconLogo {
      border: solid 2px rgb(108, 142, 154);
      border-radius: 15px;
      width: 160px;
    }
    .iconLogo img {
      width: 85px;
      height: 85px;
    }
    .iconTitle {
      font-size: 16.5px;
    }
  }

  @media screen and (max-width: 450px) {
    .avgToiletDataDiv {
      display: flex;
      justify-content: space-evenly;
      margin-left: 10px;
      margin-right: 10px;
    }
    .iconLogo {
      border: solid 2px rgb(108, 142, 154);
      border-radius: 15px;
      width: 80px;
    }
    .iconLogo:first-child {
      margin-left: 5px;
    }
    .iconLogo img {
      width: 75px;
      height: 75px;
    }
    .iconTitle {
      font-size: 15.5px;
    }
  }

  @media screen and (max-width: 400px) {
    .avgToiletDataDiv {
      display: flex;
      justify-content: space-evenly;
      padding: 12px;
      margin-left: 6px;
      margin-right: 6px;
    }
    .iconLogo {
      border: solid 2px rgb(108, 142, 154);
      border-radius: 15px;
      width: 75px;
    }
    .iconLogo img {
      width: 73px;
      height: 73px;
    }
    .iconTitle {
      font-size: 14.5px;
    }
  }

  @media screen and (max-width: 380px) {
    .avgToiletDataDiv {
      display: flex;
      justify-content: space-evenly;
      padding: 12px;
      margin-left: 6px;
      margin-right: 6px;
    }
    .iconLogo {
      border: solid 2px rgb(108, 142, 154);
      border-radius: 15px;
      width: 69px;
    }
    .iconLogo:first-child {
      margin-left: 8px;
    }
    .iconLogo img {
      width: 68px;
      height: 68px;
    }
    .iconTitle {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 350px) {
    .avgToiletDataDiv {
      display: flex;
      justify-content: space-evenly;
      padding: 12px;
      margin-left: 6px;
      margin-right: 6px;
    }
    .iconLogo {
      border: solid 2px rgb(108, 142, 154);
      border-radius: 15px;
      width: 60px;
    }
    .iconLogo img {
      width: 60px;
      height: 60px;
    }
    .iconTitle {
      font-size: 12.5px;
      margin-left: -8px;
      margin-right: -8px;
      margin-bottom: -4px;
    }
  }

  @media screen and (max-width: 310px) {
    .avgToiletDataDiv {
      display: flex;
      justify-content: space-evenly;
      padding: 12px;
      margin-left: 5px;
      margin-right: 5px;
    }
    .iconLogo {
      border: solid 2px rgb(108, 142, 154);
      border-radius: 18px;
      width: 58px;
    }
    .iconLogo img {
      width: 55px;
      height: 55px;
    }
    .iconTitle {
      font-size: 12px;
      margin-left: -8px;
      margin-right: -8px;
    }
  }

  @media screen and (max-width: 300px) {
    .avgToiletDataDiv {
      display: flex;
      justify-content: space-evenly;
      margin-left: 5px;
      margin-right: 5px;
    }
    .iconLogo {
      border: solid 2px rgb(108, 142, 154);
      border-radius: 20px;
      width: 55px;
    }
    .iconLogo img {
      width: 55px;
      height: 55px;
    }
    .iconTitle {
      font-size: 11px;
      margin-left: -8px;
      margin-right: -8px;
    }
  }

  @media screen and (max-width: 290px) {
    .avgToiletDataDiv {
      display: flex;
      justify-content: space-evenly;
      margin-left: 5px;
    }
    .iconLogo {
      border: solid 2px rgb(108, 142, 154);
      border-radius: 18px;
      width: 64px;
    }
    .iconLogo img {
      width: 52px;
      height: 52px;
    }
    .iconTitle {
      font-size: 11px;
      margin-left: -8px;
      margin-right: -8px;
    }
  }

  @media screen and (max-width: 279px) {
    .avgToiletDataDiv {
      display: flex;
      justify-content: space-evenly;
      margin-left: 2px;
      margin-right: 2px;
    }
    .iconLogo {
      border: solid 2px rgb(108, 142, 154);
      border-radius: 17px;
      width: 50px;
    }
    .iconLogo:first-child {
      margin-left: 8px;
    }
    .iconLogo img {
      width: 49px;
      height: 49px;
    }
    .iconTitle {
      font-size: 10.5px;
      margin-left: -8px;
      margin-right: -8px;
    }
  }

  @media screen and (max-width: 258px) {
    .avgToiletDataDiv {
      display: flex;
      justify-content: space-evenly;
      margin-left: 1px;
      margin-right: 1px;
    }
    .iconLogo {
      border: solid 2px rgb(108, 142, 154);
      border-radius: 17px;
      width: 48px;
    }
    .iconLogo img {
      width: 47px;
      height: 47px;
    }
    .iconTitle {
      font-size: 10px;
      margin-left: -8px;
      margin-right: -8px;
    }
  }

  @media screen and (max-width: 250px) {
    .avgToiletDataDiv {
      display: flex;
      justify-content: space-evenly;
      margin-left: 0;
      margin-left: 0;
    }
    .iconLogo {
      border: solid 2px rgb(108, 142, 154);
      border-radius: 17px;
      width: 80px;
      margin-left: 0;
    }
    .iconLogo:first-child {
      margin-left: 10px;
    }
    .iconLogo img {
      width: 45px;
      height: 45px;
    }
    .iconTitle {
      font-size: 10px;
      margin-left: -8px;
      margin-right: -8px;
    }
  }

  @media screen and (max-width: 238px) {
    .avgToiletDataDiv {
      display: flex;
      justify-content: space-evenly;
      padding: 25px;
    }
    .iconLogo {
      border: solid 2px rgb(108, 142, 154);
      border-radius: 17px;
      width: 48px;
    }
    .iconLogo:first-child {
      margin-left: -5px;
    }
    .iconLogo:first-child {
      margin-left: -8px;
    }
    .iconLogo img {
      width: 48px;
      height: 48px;
    }
    .iconTitle {
      font-size: 11px;
      margin-right: -6px;
      margin-left: -6px;
    }
  }

  /* Viliso is a IOT system for realtime clealiness and hyginee monotiroing to improve health and hygrince standards. */



