.LabelComment {
  border: 1px solid var(--primaryColor);
  border-radius: 0.5rem;
  width: 100%;
}

.CreateOrderButton{
  font-weight: bold;
  width: 30%;

}

.createOrdermobile {
  justify-content: space-evenly;
  display:flex;
  margin-top:1.5rem;
}

.createOrderSaveButton {
  margin-top: 1rem; 
  font-weight: bold;
  color: white; 
  border-radius: 0.5rem; 
  font-size: 0.9rem; 
  background-color: var(--primaryColor); 
  width: 17%; 
  height: 3rem;
}

.replacementPage {
  margin-top: 1rem; 
  font-weight: 700;
  color: rgb(59, 59, 246); 
  border-radius: 0.7rem; 
  background-color: rgb(249, 246, 246); 
}

.replacementPage:hover{
  margin-top: 1rem; 
  font-weight: 700;
  color: orange; 
  border-radius: 0.7rem; 
  background-color: rgb(249, 246, 246); 
}

.createOrdermobile1 {
  display:flex;  
  align-items:center;
}

.orderPageTitle{
  display:flex;
  justify-content: space-between;
}

.CreateOrderTotalValue {
  margin-top:1rem; 
  display:flex; 
  justify-content: flex-end;
}
.CreateOrderTotalValueInput{
  border: 1.5px solid var(--primaryColor); 
  font-weight:bold; 
  border-radius:0.3rem; 
  margin-left:0.5rem; 
  text-align:right;
}

.AddOrderDetailButton{
  font-weight: bold;
  width:25%; 
  font-size:0.9rem; 
  background-color: var(--primaryColor); 
  color:white;
  border-radius: 0.3rem;

}

.ordEnterButton{
  font-weight: bold;
  width:8%; 
  font-size:1.3rem; 
  background-color: var(--primaryColor); 
  color:white;
  border-radius: 0.3rem;
}

.createOrderTopButton {
margin-top: 0.5rem; 
color: white; 
border-radius: 0.3rem; 
font-size: 0.9rem; 
background-color: var(--primaryColor); 
font-weight: bold;
height: 1.9rem;
}

.createOrderTableButton {
border-radius: 0.3rem;
height: 0.9rem;
font-size: 0.5rem;
background-color: var(--primaryColor);
text-align: center; 
max-width: 90%;
}

.createOrderTableButton:hover{
border-radius: 0.3rem;
max-width: 90%;
height: 0.9rem;
font-size: 0.5rem;
text-align: center; 
background-color: var(--secondaryColor); 
}

.ShowOrderTableHeader {
font-size: 0.7rem;
font-weight: 600;
}

.RightAndCenterAlignedButton {
  text-align: right;
  flex: 1;
  padding-right: 1rem;
  padding-left: 1rem;
}

.LeftAndCenterAlignedButton {
  text-align: left;
  flex: 1;
  padding-right: 1rem;
  padding-left: 1rem;
}

.CenterAlignedButton {
  text-align: center;
  flex: 1;
  padding-right: 1rem;
  padding-left: 1rem;
}

.orderDownLoadCSVButton {
  padding-left: 3.3rem;
}

@media (max-width: 550px) {

.createOrdermobile {
  display:flex;
  flex-direction: column;
  margin-top:1.5rem;

}

.orderDownLoadCSVButton {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  padding-left: 0rem;
}

.orderPageTitle {
  display:flex;
  flex-direction: column;
}

.createOrdermobile1 {
  display:flex; 
  align-items:center;
  margin-top: 1rem;

}

.CreateOrderButton{
  width: 50%;
  margin-left: 0.5rem;
  font-weight: bold;

}
.createOrderSaveButton {
  margin-top: 1rem; 
  font-weight: bold;
  color: white; 
  border-radius: 0.5rem; 
  font-size: 0.9rem; 
  background-color: var(--primaryColor); 
  width: 20%; 
  height: 3rem;
}

.AddOrderDetailButton{
  font-weight: bold;
  width:70%; 
  font-size:0.9rem; 
  background-color: var(--primaryColor); 
  color:white;
  border-radius: 0.5rem;
}

.ordEnterButton{
  font-weight: bold;
  width:12%; 
  font-size:1.3rem; 
  background-color: var(--primaryColor); 
  color:white;
  border-radius: 0.3rem;

}

.createOrderTopButton {
  font-weight: bold;
  margin-top: 0.5rem; 
  color: white; 
  border-radius: 0.3rem; 
  font-size: 0.9rem; 
  background-color: var(--primaryColor); 
  height: 1.9rem;
}

.RightAndCenterAlignedButton {
  text-align: center;
  flex: 1;
}

.LeftAndCenterAlignedButton {
  text-align: center;
  flex: 1;
  margin-top: 1rem;
}

}    
