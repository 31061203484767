.searchNodeLabel{
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    text-align: left;
    font-size: 0.9rem;
    border-radius: 5px;
    border: 1px solid var(--primaryColor);
}

.btnClearInputField{
    background-color: var(--primaryColor);
    border-color: var(--primaryColor);
    margin-left: 0.3rem;
    color: white;
    font-size: 0.8rem;
    border-radius: 0.2rem;
}

.btnClearInputFieldSpinner{
    background-color: #F5FFFA;
    border-color: #C0C0C0;
    margin-left: 0.3rem;
    color: gray;
    font-size: 0.8rem;
    border-radius: 0.3rem;
    width: 1.5rem
}

.btnClearInputField:hover{
    background-color: var(--secondaryColor);
    border-color: var(--secondaryColor);
}

.btnPreviousNextSearch{
    background-color: var(--primaryColor);
    border-color: var(--primaryColor);
    margin-left: 0.3rem;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 0.2rem;
}
.btnPreviousNextSearch:hover{
    background-color: var(--secondaryColor);
    border-color: var(--secondaryColor);
}

.labelSearchResult{
    border: 2px solid var(--primaryColor);
    margin-left: 0.5rem;
    width: 5rem; 
    text-align: center;
    font-weight: 700;
}

.deviceTreeRefreshButton {
    color: white;
    background-color: var(--primaryColor);
    margin-left: 0.8rem;
    font-size: 1.1rem; 
    border-radius: 5px;
    transition: transform .4s;
}

.deviceTreeRefreshButton:hover {
    transform: scale(0.96);
}

/* .deviceTreeRefreshButton:hover {
    background-color: var(--secondaryColor);
    border: 2px solid var(--secondaryColor);
} */

.deviceTreeRefreshButtonWLI {
    color: white;
    background-color: #678AEE;
    margin-left: 0.8rem;
    font-size: 1.1rem; 
    border-radius: 5px;
}

.deviceTreeRefreshButtonSTLI {
    color: white;
    background-color: #bd9a38;
    margin-left: 0.8rem;
    font-size: 1.1rem; 
    border-radius: 5px;
}

/* .deviceTreeRefreshButton:hover {
    background-color: var(--secondaryColor);
    border: 2px solid var(--secondaryColor);
} */

.deviceTreeRefreshButtonWLI:hover {
    background-color: var(--secondaryColor);
    border: 2px solid var(--secondaryColor);
}

.deviceTreeRefreshButtonSTLI:hover {
    background-color: var(--secondaryColor);
    border: 2px solid var(--secondaryColor);
}

.SortableTreeView{
    color: var(--primaryColor);
    height: 80.5vh;
    /* overflow: auto; */
    border-top: 1px solid var(--primaryColor);
}

.SortableDefineTreeView{
    color: var(--primaryColor);
    height: 85.5vh;
    overflow: auto;
    /* width: 110.5vh; */
}

.tvOuterDiv {
    display: flex;
}


.tvInnerDivDropDown{
    margin-left: 0.4rem;
}

.tvdropDownEffect{
    box-sizing: border-box;
    box-shadow: 0rem 0.1rem 0.1rem var(--secondaryColor);
}

.Dropdown .vtDropdownToggle {
    background-color: var(--primaryColor);
    border-color: var(--primaryColor);
}

.tvStyleForDropDownOnMenuVisible{
    width: 1.2rem;
    font-size: 0.5rem;
    display: flex; 
    justify-content: center;
    align-items: center;
    margin-left: 0.1rem
}

.tvStyleForDropDownToggle{
    /* color:var(--hoverLinkColor); */
    color: gray;
    /* width: 1.2rem; */
    padding: 0.3rem;
    /* font-size: 0.5rem; */
    display: flex; 
    justify-content: center;
    align-items: center;
    transition: transform .4s;
}

.tvStyleForDropDownToggle:hover{
    background-color: white;
    border-radius: 50%;
    /* width: 1.3rem; */
    /* font-size: 0.5rem; */
    /* padding: 0.3rem; */
    cursor: pointer;
    color: var(--secondaryColor);
    /* Scaling causes problem */
    /* transform: scale(0.96); */
}



.deviceDropdownBlock {
    width: 100px;
    z-index: 999;
    position: fixed;
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: flex-start; /* Align items to the start of the flex container */
    border: 1px solid lightgray;
    background-color: white; /* Add background color for better visibility */
    padding: 8px; /* Add padding for spacing */
}

.deviceDropdownBlock span {
    margin-bottom: 8px; /* Space between spans */
    cursor: pointer; /* Change cursor to pointer to indicate clickable items */
}

/* .deviceDropdownBlock span:last-child {
    margin-bottom: 0;
} */




.tvNodeTitle{
    /* flex: 5; */
    color: #374782;
    cursor: pointer;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tvNodeTitle:hover{
    cursor: pointer;
    color:var(--hoverLinkColor);
    text-transform: capitalize;
}


.tvSelectedNodeTitle{
    text-transform: capitalize;
    /* color: var(--secondaryColor); */
    color:var(--hoverLinkColor);
    /* font-weight: bold; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex: 5; */
}

.rst__rowSearchFocus{
    border-radius: 5px;
}

.rst__rowSearchMatch{
    border-radius: 5px;
}

.rst__rowContents {
    position: relative;
    height: 3.3rem;
    border: solid var(--primaryColor) 1px;
    border-left: solid lightgray 13px;
    -webkit-box-shadow: 0 2px 2px -2px;
    box-shadow: 0 2px 2px -2px;
    padding: 0 0.4rem 0 0.7rem;
    border-radius: 2px;
    min-width: 6rem;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-color: white;
    border-radius: 5px;
}

.rst__rowWrapper {
    padding: 10px 10px 10px 0;
    height: 4.5rem;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}


.DtMobileView {
    display: none;
}

.renderDotForOfflineDevc {
    border-radius: 50%;
    border: 1px solid lightgrey;
    height: 0.5rem;
    width: 0.5rem;
    margin-right: 0.3rem;
}

.MVmobileleftRightDrawerIcon {
    color: white;
    background-color: var(--primaryColor);
    padding: 0.2rem 0.5rem 0.1rem 0.5rem;
    /* padding: 0.5rem 0.5rem 0.1rem 0.5rem; */
    border-radius: 0.2rem;
    margin-top: 0.5rem;
    margin-right: 0.2rem;
    text-align: center;
    font-size: 1.4rem;

  }

  .MVmobileleftRightDrawerIcon:hover {
    color: white;
    background-color: #56a9ca;
    padding: 0.2rem 0.5rem 0.1rem 0.5rem;
    border-radius: 0.2rem;
  }

@media (max-width: 550px) {
    .DtMobileView {
        display: flex;
        justify-content: center;
    }
}



.deviceItem {
    color: black;
}

.deviceItem:focus {
    background-color: rgb(233, 233, 233);
}

.myMenu .deviceItem:hover {
    background-color: transparent !important;
}

.deviceBlock {
    background-color: #f7f9fa;
    margin: 3px 3px 3px 4px;
    border-radius: 5px;
    margin-left: 3px;
    border-bottom: 1px solid lightgray;

}

.deviceBlock:hover {
    background-color: rgba(233, 233, 233);
}

.tvStyleForDropDownToggle:focus {
    color: black;
}



/* 
.ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline, .ant-menu-light>.ant-menu.ant-menu-inline .ant-menu-sub.ant-menu-inline {
    background-color: white !important;
    margin-left: 23px !important;
    border-radius: 5px !important;
} */

.myMenu .ant-menu-item >.ant-menu-title-content,
.myMenu .ant-menu-submenu-title >.ant-menu-title-content {
    margin-left: -18px !important;
}

.myMenu .ant-menu-title-content:hover {
    background-color: transparent !important;
}

.myMenu .ant-menu-sub.ant-menu-inline {
    background-color: transparent !important;
    margin-left: 23px !important;
    border-radius: 5px !important;
}

.myMenu .ant-menu-item-selected {
    background-color: transparent !important;
    color: black !important;
}

.mySelector .ant-select-selector {
    border-radius: 100px !important;
    border-color: var(--primaryColor) !important;

}
/* .myMenu .ant-menu-light:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu.item-selected),
.myMenu .ant-menu-light:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu.item-selected):hover {
    background-color: blue !important;
} */

/* .ant-menu-light:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu.item-selected):hover {
    background-color: transparent !important;
} */
/* 
.mySelector.ant-select.css-2i2tap.ant-select-single.ant-select-show-arrow .ant-select-selector {
    border-radius: 100px !important;
}

.deviceItem.ant-menu-item-selected {
    background-color: transparent !important;
    color: black !important;
}

.mySelector > .ant-menu-submenu-title {
    padding-left: 5px !important;
}

.mySelector > .ant-menu.ant-menu-sub.ant-menu-inline {
    padding-left: 13px !important;
}

.deviceItem.ant-menu-item.ant-menu-item-active.ant-menu-item-selected.ant-menu-item-only-child {
    padding: 7px 0px !important;
    margin-left: 0px !important;
} */

/* .myMenu .ant-menu-light:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu.item-selected),
.myMenu .ant-menu-light:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu.item-selected):hover {
    background-color: blue !important;
} */




/* .ant-menu-light.ant-menu-inline .ant-menu-item::after, .ant-menu-light>.ant-menu.ant-menu-inline .ant-menu-item::after {
    background-color: red !important;
} */

/* .mainBlock {
    display: flex;
    flex-direction: column;
} */

#parameterSubTitle {
    /* font-size: 5px; */
    font-weight: normal;
    margin-top: -100px;
}
.ant-menu-submenu-selected > .ant-menu-submenu-title {
    color: black !important;
}

.shimmerUI {
    height: 100vh;
    background: linear-gradient(-45deg, #f7f9fa 40%, #e3e8ea 50%, #f4f4f4 60%);
    background-size: 300%;
    animation: shimmer 2s infinite linear;
    background-position-x: 100%;
    border-left: 5px solid #ececec;
    border-radius: 0.2rem;
    border-right: 5px solid #f7f9fa;
    margin: 0px 1px;
}


@keyframes shimmer {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
}
