.trackradioBox{
    display: flex;
    justify-content: center;
    border: solid;
    border-width: 2px;
    padding: 0.8rem;
    border-color:  var(--primaryColor);
  }

  .trackRadioDiv {
    display: flex; 
    justify-content: center;
    margin-bottom: 1.2rem;
  }

  .trackFrmLabel{
    width: 20%;
    /* margin-top: 0.5rem; */
    text-align: left;
    vertical-align: middle;
    font-size: 0.9rem;
    margin: auto; /* Important */ 
    /* text-align: center;  */
  }

  .trackFrmInput{
    width: 70%;
    text-align: left;
  }

  .trackFrmLblAndInputDiv{
    /* width: 100%, */
    flex: 1
  }

  .trackFrmrow{
    display: flex;
    margin-bottom: 1rem;
  }

  .trackFrmInputForm {
    border:1px solid var(--primaryColor);
    text-align: Left;
    width: 100%;
    border-radius: 5px;
    height: 2.2rem;
   }

  @media(max-width: 550px){

    .trackFrmLblAndInputDiv{
      /* flex: ""; */
      width: 100%
      /* display: flex;
      flex-direction: column;
      align-items: flex-start; */
    }

    .trackFrmrow{

      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .trackRadioDiv {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .trackradioBox{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  
    .trackFrmLabel{
      width: 100%;
      margin-bottom: 0px;
    }

    .trackFrmInput{
      width: 100%;
    }
  }
