.AddRemoveUserWithOrgInputForm {
  border:1px solid var(--primaryColor);
  text-align: Left;
  width: 130%;
  border-radius: 5px;
  height: 1.6rem;
  }

  .AddRemoveUserWithOrgLabel{
  margin-bottom: 0px;
  width: 100%;
  text-align: left;
  font-size: 0.8rem;
}

.AddRemoveUserInputForm {
  border:1px solid var(--primaryColor);
  text-align: Left;
  width: 86%;
  border-radius: 5px;
  height: 2.2rem;
}

.btnAddRemoveUserToTheNode {
  padding: 0.3rem;
  border-radius: 5px;
  margin-left: 0.5rem;
  background-color: var(--primaryColor);
  color: white;
  border: var(--primaryColor);
  font-weight: 700;
  width: 5rem;
  float: right;
}

.addRemoveUserInputField{
  width: 75%;
  text-align: left;
  display: flex;
  flex-direction: row;
}

.addRemoveUserToNodeError{
  color : var(--errorColor);
  font-size: 0.9rem;
  width: 100%;
  text-align: center;
  margin-top: 0.3rem;
}

@media(max-width: 550px){
  .AddRemoveUserWithOrgInputForm {
    border:1px solid var(--primaryColor);
    text-align: Left;
    width: 95%;
    border-radius: 5px;
    height: 1.6rem;
    margin-bottom: 0.2rem;
  }

  .AddRemoveUserInputForm {
    border:1px solid var(--primaryColor);
    text-align: Left;
    width: 100%;
    border-radius: 5px;
    height: 2.2rem;
  }

  .btnAddRemoveUserToTheNode {
    padding: 0.3rem;
    border-radius: 5px;
    margin-left: 0.5rem;
    margin-top: 0.5rem;
    background-color: var(--primaryColor);
    color: white;
    border: transparent;
    font-weight: 700;
    width: 5rem;
  }

  .addRemoveUserInputField{
    width: 100%;
    flex-direction: column;
  }

}