
:root {
    --gray-100: rgb(243 244 246);
    --gray-200: rgb(229 231 235);
    --gray-300: rgb(209 213 219);
    --slate-50: rgb(248 250 252);
    --slate-100: rgb(241 245 249);
    --slate-200	: rgb(226 232 240);
    --slate-300	: rgb(203 213 225);
    
}

.searchBoxInput {
    outline: none;
    border: none;
    width: 100%;

}

.top-nav-active {
    /* border: 1px solid green; */
    color: green !important;
}

.animateScale {
    transition: all;
    transition-duration: 150ms;
}

.animateScale:hover {
    scale:1.2;
}

.top-nav {
    /* color:var(--gray-300) */
    color:black;

    height: 30px;
    width: 30px;
    overflow: visible;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    transition: all;
    transition-duration: 150ms;

}

.side-nav {
    height: 30px;
    width: 30px;
    overflow: visible;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    transition: all;
    transition-duration: 150ms;
    font-size: 17px;
    color:white !important;
    min-width: 30px;

}

.side-nav:hover {
  color: #498044 !important;
}

.side-nav-active {
  /* border: 1px solid red; */
  background-color: white;
  /* color: #0d6efd !important; */
  color: green !important;
}

.crmIcon:hover {
  color: #498044 !important;
}

.top-nav:hover {
    color: green;
}

.crmIcon {
  font-weight: 600; 
  font-size: 0.85rem; 
  font-style: none;
  color: white;
}

.crmIconActive {
  font-weight: 600; 
  font-size: 0.65rem; 
  color: green;
}


.profileDiv:hover {
    /* background-color: var(--gray-200); */
    background-color: black;
    color: white;
}

.profileDiv {
    height: 30px;
    width: 30px;
    display: grid;
    place-items: center;
    border-radius: 50%;
    border: 1px solid gray;
    cursor: pointer;
    overflow: hidden;
}

.analysis {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.params {
    width: 100%;
}

.bg-main {
  background-color: #f7f9fa;
}

.rounded-xl {
  border-radius: 15px;
}

.messageBox {
  padding: 20px 10px;
  border-radius: 15px;
  background-color: white;
}

.buttonStyle {
  background-color: var(--primaryColor);
  font-size: 1.1rem;
  color: white;
  outline: none;
  padding: 0.3rem .6rem;
  transition: all;
  /* font-size: 0.9rem; */
  transition-duration: 150ms;
  border: none;
  border-radius: 5px;
}

.pfcBoxes {
  width: 32%;
}

.buttonStyle:hover {
  transform: translateY(-1px);
  /* background-color: var(--secondaryColor) */
  background-color: rgba(5, 136, 230, 0.779);
}



.refreshButtonStyle {
  display: grid;
  place-items: center;
  background-color: #f7f9fa;
  width: 30px;
  height: 30px;
  border: 1px solid lightgray;
  color: darkgreen;
  border-radius: 10px;
  transition: all;
  box-shadow:0px 3px 3px rgba(0, 0, 0, 0.4)
}

/* .refreshButtonStyle:hover {
  transform: translateY(-2px);
} */

/* .refreshButtonStyle:hover i {
  animation: spin 1s 1 ease-in;
} */

.spinn {
  animation: spin 1s infinite linear;
}

.selectedNodeTitle {
  font-weight: bold;
  /* color: #303597; */
  color: #0d6efd;
}

.top-nav-active .filledIcon {
  display: block;
}

.top-nav-active .outlineIcon {
  display: none;
}

.top-nav .filledIcon {
  display: none;
}
.top-nav .outlineIcon {
  display: block;

}


@media (min-width: 768px) {
    .analysis {
        width: 60%;
    }
    .params {
        width: 40%;
    }
}

@media (max-width: 550px) {
    .top-nav {
        /* height: 50px; */
        font-size: 1.3rem;
        /* width: 50px; */
    }
    .pfcBoxes {
      width: 100%;
    }
}



.treeVisible {
    /* position: relative; */
    /* width: fit-content; */
    width: 22%;
    transition: all;
    transition-duration: 150ms;
    transition-timing-function: ease-in-out;
}

.contentWithTree {
  width: 77%;
  padding-left: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.contentWithoutTree {
  width: 100%;
  padding:10px 15px

}




.treeHidden {
    /* display: none; */
    /* position: relative; */
    width: 0px;
    height: 0px;
    /* background-color: red; */
    overflow: hidden;
    transform: translateX(-100%);
    z-index: -1;
    transition: all;
    transition-duration: 150ms;
    /* transition-timing-function: ease-in-out; */
    transition-timing-function: linear;
    /* display: none; */
}


.tooltipParent {
    position: relative;
    /* background-color: red; */
}


.tooltip2 {
    position: absolute;
    /* display: block; */
    border: 1px solid black;
    top: 0;
    left: 100%;
    /* min-height: 20px;
    min-width: 100px; */
    /* margin-top: 50%; */
    /* transform: translateY(-50%); */
    /* right: -500%; */
    /* margin-right: -100%; */
    padding: 2px;
    min-width: 400px;
    text-align: left;
    z-index: 1; 
    background-color: wheat;

}

.gridView {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px;
    transition: all;
    /* max-height: 20vh; */
    border: 2px solid transparent;
    cursor: pointer;
    height: 100%;
}

/* .gridView {
    width: 47%;
    max-width: 50%;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px;
    transition: all;

    border: 2px solid transparent;
    cursor: pointer;
    min-height: 20vh;
} */

.wliBox {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px;
    transition: all;
    /* min-width: 50%; */
    /* width: fit-content; */
    border: 2px solid transparent;
    cursor: pointer;
    /* min-height: 25vh; */
}

.blue {
    backdrop-filter:blur(5px);
}

.shimmer {
    background-color: gray;
}

.listView {
    /* min-width: 45%; */
    /* width: 97%; */
    /* width: 100%;
    background-color: white;
    border-radius: 10px;
    transition: all;
    display: flex; */
    /* justify-content: space-between; */
    border: 2px solid transparent; 
    transition: all;
    cursor: pointer;
}

.paramBoxBorder {
  border: 2px solid #1875f6aa; 
}

.directionArrow {
  display: flex;
  align-items: center;
  border: 2px solid #2C74B3;
  font-size: 2.5rem;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  justify-content: center;
}

.selected {
    border-color: green;
}
/* 
.treeToggler {
  z-index: 1;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  scale: 1.3;
  transition: all;
} */

.treeToggler.open {
  /* left: 90%; */
  transform: rotate(0);


}

.treeToggler.close {
  /* left: 100%; */

  transform: rotate(180);
}


.shadow-2 {
  box-shadow: 0 0 5px rgba(156, 155, 155, 0.806);
}
.mainDeviceHome {
    /* background-color: var(--gray-100); */
    /* background-color: var(--gray-100); */
    /* background-color: var(--gray-200); */
    /* background-color: var(--slate-100); */
    background-color: var(--slate-200);
    /* background-color: var(--slate-300); */
    /* background-color: #e9eff7;f */
    /* background-color: #e8edfc; */
    /* background-color: #E6E6FA; */
    /* background-color: var(--gray-300); */
    /* background-color: var(--gray-300); */
  
    /* background-color: #63b26730; */
    /* background-color: #e6e6a8; */
    /* background-color: lightgrey;
    background-color: rgba(124, 142, 160, 0.221); */
}
.sideNavBg {
  /* background-color: rgb(81, 87, 200); */
  /* background-color: white; */
  /* background-color: #374782; */
  background-color: #374782;
}
/* .mainDeviceHome::before {
    content: "";
    width: 100%;
    height: 30vh;
    background-color: blue;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
} */


.filtersDivPar {
    /* max-height: 100%; */
    overflow: hidden;
    /* border-radius: 0px 0px 5px 5px; */
    border-radius: 5px


}

.filtersDivPar:hover {
    /* max-height: 100%; */
    overflow: visible;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.51);

}

.filtersDiv {
    background-color: white;
    padding: 2px 0;
    margin-top: -5px;
    width: 100%;
    border-radius: 0px 0px 5px 5px;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.51);
    
}


.absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}


.spinner {
    height: 50px;
    /* background-color: red; */
    width: 50px;
    border: 5px solid green;
    border-radius: 50%;
    border-bottom-color: transparent;
    animation: spin 1s infinite linear;
}

.cpmsg {
    /* position: absolute; */
    background-color: white;
    border-radius: 4px;
    padding: 0 1px;
    animation: slidein 1s ease-in-out;
}

@keyframes slidein {
    0%{
        opacity: 0;
        /* left: 0%; */
    }
    50% {
        opacity: 1;
        /* left: 110%; */
    }
    100% {
        opacity: 0;
        /* left: 0%; */
    }
    
}

@keyframes spin {
    0% {
        rotate: 0deg;
    }
    50% {
        rotate: 180deg;

    }
    100% {
        rotate:360deg;
    }
}




/* For liquid progress */
.box1 {
    opacity: 0.7;
    /* border-radius: 50px 60px 40px 40px; */
    border-radius: 20% 30%;
    animation: rotate2 5s linear infinite;
}

.box2 {
    animation: rotate2 3s linear infinite;
    border-radius: 30% 40%;
    transform: translateX(-10%);

    /* opacity: 0; */
}

.box3 {
    animation: rotate2 4s linear infinite;
    border-radius: 30% 40%;
    /* scale: 1.1; */
}

@keyframes rotate2 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}




  .circle{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150px;
    height: 150px;
    /* border: 5px solid #FFFFFF; */
    border: 1px solid #3e3c43b5;
    /* box-shadow: 0 0 0 5px #4973ff; */
    border-radius: 50%;
    overflow: hidden;
  }
  .wave{
    position: relative;
    top: 0;
    width: 100%;
    height: 100%;
    /* background: #2C74B3; */
    background: #66b8ff;
    border-radius: 50%;
    /* box-shadow: inset 0 0 50px 0 rgba(0, 0, 0, 0.5); */
  }

  .startBlueBorder {
    /* border-color: #66b8ff !important; */
    border-left: 4px solid #66b8ff;
  }

  .wave2 {
    position: absolute;
    top: 0;
    left: 50%;
    width: 200%;
    height: 200%;
    background: black;
    transform: translate(-50%, -75%);
  }
  .wavea {
    border-radius: 45%;
    background: rgba(255, 255, 255, 1);
    animation: animate 5s linear infinite;
  }
  .waveb{
    border-radius: 40%;
    background: rgba(255, 255, 255, 0.5);
    animation: animate 10s linear infinite;
  }
  /* .wave:before,
  .wave:after{
    content:'';
    position: absolute;
    top: 0;
    left: 50%;
    width: 200%;
    height: 200%;
    background: black;
    transform: translate(-50%, -75%);
  } */
  /* .wave:before{
    border-radius: 45%;
    background: rgba(255, 255, 255, 1);
    animation: animate 5s linear infinite;
  }
  .wave:after{
    border-radius: 40%;
    background: rgba(255, 255, 255, 0.5);
    animation: animate 10s linear infinite;
  } */
  

  .stliMeter {
    width: 250px;
    height: 150px;

  }

  /* @media (max-width: 550px) {
    .stliMeter {
      width: 150px;
      height: 100px;
    }
  } */
  @keyframes animate{
    0%{
      transform: translate(-50%, -75%) rotate(0deg);
    }
    100%{
      transform: translate(-50%, -75%) rotate(360deg);
    }
  }

  .rightCornerButton {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 5;
  }

  
  .drawerDiv {
    width: 25%;
    top: 0;
    transition: all;
    transition-duration: .5s;
    position: fixed;
    z-index: 999;
  }

  .drawerDivIn {
    left: -25%;
    transform: translateX(100%);
  }

  .drawerDivOut {
    left: -25%;
    transform: translateX(-100%);

  }


  .closingArrow {
    position: absolute;
        right: 0;
        top: 10px;
        transform: translateX(50%);
        border: 2px solid white;
        background: rgba(0, 0, 0, 0.79);
        color: white;
        height: 30px;
        width: 30px;
        border-radius: 50%;
    
  }


  .tooltipPar {
    position: relative;
    display: inline-block;
    /* z-index: 1000; */
  }

  .tooltiptextbottom,
  .tooltiptextRight {

    visibility: hidden;
    background-color: #555;
    color: white;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    font-size: 0.9rem;
    position: absolute;
    z-index: 1;
    left: 200%;
  
    opacity: 0;
    transition: opacity 0.3s;

  }

  .tooltipPar:hover .tooltiptextRight,
  .tooltipPar:hover .tooltiptextbottom
  {
    visibility: visible;
    opacity: 1;
  }
  
  /* Tooltip text */
  .tooltipPar .tooltiptextRight {
    /* width: min-content; */
    width: 150px;
    /* padding: 0px 5px; */
  }
  
  .tooltipPar .tooltiptextRight::after {
    content: " ";
    position: absolute;
    top: 50%;
    right: 100%; /* To the left of the tooltip */
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent black transparent transparent;
  }
  
  


  .tooltipPar .tooltiptextbottom {
    width: 130px;
    top: 140%;
    left: 50%;
    margin-left: -65px; 
  }

  .tooltipPar .tooltiptextbottom::after {
    content: " ";
    position: absolute;
    bottom: 100%;  /* At the top of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
  }

  .blurImg {
    filter: blur(2px);
  }


  .primaryButton {
    background-color: var(--primaryColor);
    color: white;
  }

  .primaryButton:hover {
    font-weight: bold;
    color: white;
    outline: none;
    
  }


  @keyframes shimmer-animation {
    0% {
      transform: translateX(-50%);
    }
    100% {
      transform: translateX(50%);
    }
  }
  

  .sideNav {
    width: 4%;
    position:"fixed";
    z-index: 5;
  }


.treeAndContent {
  width: 96%;
  /* margin-left: 4%; */
}

.treeAndContentCRM {
  width: 96%;
  /* background-color: #e2e8f0; */
}

@media (max-width: 770px) and (min-width: 570px) {
  .sideNav {
    width: 10%;
  }

  .treeAndContent {
    width: 90%;
    /* margin-left: 4%; */
  }

  .treeAndContentCRM {
    width: 96%;
    /* background-color: #e2e8f0; */
  }

  .treeVisible {
    width: 40%;
  }

  .contentWithTree {
  width: 60%;
  }

}

