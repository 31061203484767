.App {
  text-align: center;
  font-family: sans-serif;
}
.App-link {
  color: #61dafb;
}
.MenuBox{
  box-shadow: gray 0px 10px 20px, gray 0px 6px 6px;
  padding-top: 1rem; 
  border-top-left-radius: 30px; 
  border-bottom-left-radius: 30px;  
  background: linear-gradient(90deg, rgba(73,128,68,1) 0%, rgba(133,194,114,1) 100%);
  height: 90vh;
  padding: 1rem;
  padding-top: 0.7rem;
}

.MenuBoxRow{
  justify-content: end;
  align-items: center;
  padding-right: 0rem;
}

.MenuBoxCol{
  padding-right: 0rem;
}

  
.login{
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1.5rem;
  box-sizing: border-box;
  box-shadow:  0 0.3rem 0.7rem var(--primaryColor);

}

.LoginBox{
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  background-color: white;
  height: 90vh;
  padding: 1rem;
  padding-top: 0.7rem;
}

.LoginBoxDiv{
    justify-content: start;
    align-items: center;
}

.LoginBoxCol{
  background: linear-gradient(90deg, rgba(73,128,68,1) 0%, rgba(133,194,114,1) 100%);
  height: 100vh;
  overflow-y: auto;
  padding-left: 0rem;
  padding: 2rem;

}

.Products{
  padding: 1rem;
  margin: 2rem
}

.Carousel{
  background-color: white; 
  padding-bottom: 1rem;
  margin-top: 3rem; 
  opacity: 0.9; 
  border-radius: 15px 50px; 
  width: 35vw; 
  margin: auto; 
  text-align: center
}

/* / Custom styles for Ant Design Carousel / */
.Carousel .slick-dots li button {
  background-color: gray; 
}

.Carousel .slick-dots li.slick-active button {
  background-color: #303597; 
}
/* / Custom styles for Ant Design Carousel / */


.forgot-p{
  color: #043e73;
}

.forgot-p:hover{
  text-decoration: underline;
}

.loginFooterLinks {
  color: #043e73;
  align-items: center;
}

.loginFooterLinks:hover{
  text-decoration: underline;
}

.DvcImg{
  border-radius: 30%;
  width: 100%; 
  height: 100%;
}

.DvcName{
  font-size: 1.5rem; 
  color: #303597;
  font-weight: bold;
}

.DvcDescription{
  font-size: 0.9rem;
  color: #414142;
  font-weight: bold;
}

.ImgAndDvcBox{
  height: 30vh;
  border-radius: 20%; 
  background-color: #E6E6FA;
}

.CorouselContainer{
  background-color: #E6E6FA;
  border-radius: 15px 50px
}


.loginMapBtn {
  text-decoration: none;
}

.loginMapBtn:hover {
  font-weight: bold;
}

.login img{
  height: 5rem;
  box-sizing: border-box;
  /* box-shadow: 0 0.3rem 0.7rem #dcecdc; */
  width: 12rem;
}

.login .logform{
  margin-bottom: 1rem;
}

.log-form-label{
  /* margin-bottom: 0.5rem; */
  width: 100%;
  text-align: left;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.login .login-input
{
  border:1px solid var(--primaryColor);
  width: 100%;
  border-radius: 5px;
  margin-bottom: 0.5rem;
  height: 2.5rem;
}

.login .logbtn{
  background-color: #303597;
  /* border-color: var(--primaryColor); */
  width: 100%;
  text-transform: uppercase;
  color: white; 
  margin-top: 1rem;
  border-radius: 5px;
  padding:0.4rem;
  font-size: 1.2rem;
  opacity: 0.9;
  
}

.login{
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1.5rem;
  box-sizing: border-box;
  box-shadow:  0 0.3rem 0.7rem var(--primaryColor);

}

.login .btn:hover
{
  color: white; 
}

.login .log-foot
{
  /* height: 2rem; */
  border-top: 1px solid var(--primaryColor); 
  margin-top: 1rem; 
  
}
.login .log-foot a{
  margin-top: 1rem;
  color: #043e73;
}
.login a:hover{
  text-decoration: underline;
}

.loginFooterLinks {
  color: #043e73;
  align-items: center;
}

.forgot-p{
  color: #043e73;
}

label{
  margin-bottom: 0.5rem;
}

.viewToiletsBtn {
  color: white;
  background-color: var(--primaryColor);
  border: var(--primaryColor);
  font-size: 1.1rem;
  border-radius: 5px;
}

.firstmodalslide {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 80vw; */
  height: 70vh;
  padding-right: 1.4rem;
  padding-left: 1.2rem;
  background-color: var(--primaryColor);
}

.secondmodalslide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  /* width: 80vw; */
  height: 70vh;
  padding-left: 1rem;
  padding-right: 1.5rem;
  background-color: var(--primaryColor);
}

.thirdmodalslide {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: left;
  /* width: 80vw; */
  height: 70vh;
  padding: 1rem;
  background-color: var(--primaryColor);
} 

.fourthmodalslide {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
  text-align: center;
  /* width: 80vw; */
  height: 70vh;
  padding: 1rem;
  background-color: var(--primaryColor);
} 
.thirdmodalslide p a {
  font-size: 1.2rem;
}

.thirdSliderHeader{
  font-size: 1.5rem;
  color: white;
  text-align: center;
}
.thirdSlideList { 
  text-align: left;
}
.thirdSliderSubComp{
  font-size: 1.2rem;
  color: white;
}

.DeviceIdLabel {
  width:80%;
  font-size:0.9rem;
  text-align: left;
  margin-left: 25%;
  color: white;
  font-weight: 600;
  margin-bottom: 0rem;
}

.DeviceIdInput {
  background: #F5F5F5;
  text-align: left;
  border: 1px solid var(--primaryColor);
  width: 50%;
  height: 2rem;
  border-radius: 5px;
  margin-bottom: 0.5rem;
}

.qrcodeTextBtn {
  display: inline-block; 
  cursor: pointer;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
}

.qrcodeTextBtn:hover {
    cursor: pointer;
    color: var(--secondaryColor);
}

.qrcodeTextBtn:focus {
    cursor: pointer;
    color: var(--secondaryColor);
}

.qrcodeTextBtnIcon {
    font-size: 1.3rem;
    margin-left: -1.7rem; 
}

.QRCodeCamBox {
    width: 30%;
}

.QRCodeCamBoxForModalAndContainer {
  width: 50%;
}


/* / Custom CSS / */

@media (max-width: 768px) {

  .vilisoLogo{
    width: 120px;
    height: 55px;
  }

  .welcomeText {
    font-size: 21px; /* Medium screens */
  }

  .Carousel{
    background-color: white; 
    padding-bottom: 1rem;
    margin-top: 3rem; 
    opacity: 0.9; 
    border-radius: 15px 50px; 
    width: 80vw; 
    margin: auto; 
    text-align: center;
  }

  .LoginBox{
    box-shadow: none;
    border-radius: 0px;
    border-radius: 0px;
    background-color: white;
    height: 100vh;
    padding: 1rem;
    margin: 3rem;
  }

  .LoginBoxDiv{
    justify-content: center;
    align-items: center;

}

  .LoginForm{
    height: 80vh;
    padding-bottom: auto;
  }

  .MenuBox{
    padding-top: 1rem; 
    background: linear-gradient(90deg, rgba(73,128,68,1) 0%, rgba(133,194,114,1) 100%);
    height: 86vh;
    padding: 1rem;
    padding-top: 0.7rem;
  }

  .MenuBoxCol{
    padding-left: 0rem;
  }

  .LoginBoxCol{
    padding: 0rem;
    background: white;
    height: 100vh;
    padding: 1rem;
  }

}

@media (max-width: 1024px) {

  .vilisoLogo{
    width: 30%;
    height: 40%;
  }

  .welcomeText {
    font-size: 18px; /* Extra large screens */
  }

  .Carousel{
    background-color: white; 
    padding-bottom: 1rem;
    margin-top: 3rem; 
    opacity: 0.9; 
    border-radius: 15px 50px; 
    width: 35vw; 
    margin: auto; 
    text-align: center;
  }
}

@media (min-width: 1024px) {

  .vilisoLogo{
    width: 30%;
    height: 40%;
  }

  .welcomeText {
    font-size: 21px; /* Extra large screens */
  }

  .Carousel{
    background-color: white; 
    padding-bottom: 1rem;
    margin-top: 3rem; 
    opacity: 0.9; 
    border-radius: 15px 50px; 
    width: 35vw; 
    margin: auto; 
    text-align: center;
  }

  .LoginForm{
    height: 75vh;
    padding-bottom: auto;
  }

  .LoginBox{
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  background-color: white;
  height: 90vh;
  padding: 0rem;
  padding-top: 0rem;
}
  
}

@media (min-width: 1200px) {

  .vilisoLogo{
    width: 150px;
    height: 60px;
  }

  .welcomeText {
    font-size: 21px; /* Extra large screens */
  }

  .signInBtn{
    font-size: 0.3rem;
  }

  .Carousel{
    background-color: white; 
    padding-bottom: 1rem;
    margin-top: 3rem; 
    opacity: 0.9; 
    border-radius: 15px 50px; 
    width: 35vw; 
    margin: auto; 
    text-align: center;
  }

  .LoginForm{
    height: 75vh;
    padding-bottom: auto;
  }

  .LoginBox{
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  background-color: white;
  height: 90vh;
  padding: 0rem;
  padding-top: 0rem;
}
  
}

@media (min-width: 1400px) {

  .signInBtn{
    font-size: 0.3rem;
  }
  .vilisoLogo{
    width: 160px;
    height: 70px;
  }

  .welcomeText {
    font-size: 23px; /* Extra large screens */
  }

  .Carousel{
    background-color: white; 
    padding-bottom: 1rem;
    margin-top: 3rem; 
    opacity: 0.9; 
    border-radius: 15px 50px; 
    width: 35vw; 
    margin: auto; 
    text-align: center;
  }

  .LoginForm{
    height: 75vh;
    padding-bottom: auto;
  }

  .LoginBox{
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  background-color: white;
  height: 90vh;
  padding: 0rem;
  padding-top: 1.3rem;
}
  

@media (max-width: 550px) {

  .thirdSliderHeader{
    font-size: 1.2rem;
    color: white;
    text-align: center;
  }

  .thirdSlideList { 
    text-align: left;
    height: 40vh;
    overflow: auto;
  }

  .DeviceIdLabel {
    width:80%;
    font-size:0.9rem;
    text-align: left;
    margin-left: 10%;
    color: white;
    font-weight: 600;
    margin-bottom: 0rem;
  }
  
  .DeviceIdInput {
    background: #F5F5F5;
    text-align: left;
    border: 1px solid var(--primaryColor);
    width: 70%;
    height: 2rem;
    border-radius: 5px;
    margin-bottom: 0.5rem;
  }

  .QRCodeCamBox {
    width: 100%;
  }
  
  .QRCodeCamBoxForModalAndContainer {
    width: 100%;
  }

}

}

