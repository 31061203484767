.task{
  border-radius: 0.5rem;
  align-items: center;
  justify-content: space-between;
  border: 1px solid lightgray;
  cursor: pointer;
  margin: 0.3rem;
  padding: 0.3rem;
  display: flex;
  font-size: 0.9rem;
}

.task span{
  font-size: 0.9rem;
}
.task button{
  background-color: #ffffff ;
  color: var(--primaryColor);
  font-size: 1rem;
  border: none;
  cursor: pointer;
  outline: none;
  height: 1.5rem;
  width: 1.6rem;
  border-radius: 0.5rem;
  padding: 0rem;
}

















