
.ReactTable.-striped .rt-tr.-odd{
    /* background: rgba(14, 92, 3, 0.4);   */
    /* background:  rgba(198, 217, 242, 0.5);  */  
    background:  rgba(110, 146, 192, 0.5);
}
.ReactTable.-striped .rt-tr.-even{
    /* background: rgba(35, 80, 30, 0.4); */
    background:  rgba(162, 194, 236, 0.5);

}


.ReactTable.-striped .rt-td{
    text-align: right;
    padding-right: 1rem;
}

.deviceNameCell:hover{
    color:var(--hoverLinkColor);
    cursor: pointer;
    font-weight: 900;
}

.ReactTable .rt-resizable-header {
    padding: 6px 24px !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}
 
.ReactTable .rt-resizer:before {
    display: inline-block;
    position: absolute;
    right: 25px;
    top: 3px;
    height: 18px;
    width: 18px;
    color: transparent;
    content: '.';
    background-size: 18px 18px;
    background-repeat: no-repeat; 
    opacity: 0.87;
}

.ReactTable  .rt-resizable-header-content:after {
    position: absolute;
    right: 8px;
    top: 3px;
    height: 18px;
    width: 18px;
    z-index: 120;
    color: transparent;
    content: '.';
}

.ReactTable .rt-th.-sort-asc .rt-resizer:before {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTAgMTVWNmw0IDQgMS0xLTYtNi02IDYgMSAxIDQtNHY5eiIgZmlsbD0iIzMzMyIvPjwvc3ZnPg==);  
}


.ReactTable .rt-th.-sort-desc .rt-resizer:before {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNOCAzdjkuMTNMNCA4IDMgOWw2IDYgNi02LTEtMS00IDQuMTNWM3oiIGZpbGw9IiMzMzMiLz48L3N2Zz4=);
}

.VcAddNameOfParameter{
    text-transform: capitalize;
    font-size: 0.9rem;
    margin: 0rem;
    font-weight: 600;
}